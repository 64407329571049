import React, {useEffect, useState} from "react";
// import { connect } from "react-redux";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../../../_metronic/_helpers";
import { injectIntl } from "react-intl";
import {useSelector, useDispatch} from "react-redux";
import {fetchorders, submitOrders, saveOrders} from "../ordersCrud";
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import {cartCountActions} from "../../../../../redux/reducers/cartCount";
import Stripe from "react-stripe-checkout";
import Input from '@material-ui/core/Input';
import axios from 'axios';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import {Select} from "@material-ui/core";
import FilledInput from "@material-ui/core/FilledInput";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import OrderSummary from "../OrderSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
    ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    ListSubheader,
    TextField,
    Collapse
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%"
    },
    heading: {
        fontSize: theme.typography.pxToRem(20),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    }
}));

function ConfirmationPage(props) {

    const classes = useStyles();

    const dispatch = useDispatch();
    const [orders,setOrders] = useState([]);
    const [currOrder,setCurrOrder] = useState(null);
    const [currOrderStatus,setCurrOrderStatus] = useState(null);
    const [orderFields,setOrderFields] = useState([]);
    const [isOrdersFetched, setIsOrdersFetched] = useState(false)
    const [isCartEmpty, setIsCartEmpty] = useState(false)
    const [messageBO, setMessageBO] = useState("");
    const [openBO, setOpenBO] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [dialogMessage, setDialogMessage] = useState("");
    const [isOrdersValid, setIsOrdersValid] = useState(false)
    const [isAllSubmit, setIsAllSubmit] = useState(false)
    const [isSelectedSubmit, setIsSelectedSubmit] = useState(false)
    const [isOrderSummaryClicked, setIsOderSummaryClicked] = useState(false)
    const [isOrderUpdated, setIsOrderUpdated] = useState(false)
    const [isSubmitCompleted, setIsSubmitCompleted] = useState(false)
    const [clickedOrder, setClickedOrder] = useState({})
    const [defaultDate, setDefaultDate] = useState("");

    const [selectedOrders,setSelectedOrders] = useState([]);
    const [invalidSelectedOrders,setInvalidSelectedOrders] = useState([]);

    const [expanded, setExpanded] = useState("");

    const {user,accessToken} = useSelector(
        ({auth}) => ({
            user: auth.user != null ? auth.user : null,
            accessToken: auth.authToken,
        })
    )

    function handleClickOpen() {
        setOpen(true);
    }

    function handleClose() {
        setOpen(false);
        setOpenBO(false)
        setCurrOrder(null)
        setInvalidSelectedOrders([])
        setIsSelectedSubmit(false)
        setIsAllSubmit(false)
        if(isOrdersValid && !currOrder && isSubmitCompleted){
            dispatch(cartCountActions.getProductCount(accessToken, user.id))
            props.history.push('/orders');
        }
    }

    function handleCloseBO() {
        setOpenBO(false);
    }

    function handleClickOpenBO() {
        setOpenBO(true);
    }

    const handleAccordionChange = (panel) => (event, newExpanded) => {

        setExpanded(newExpanded ? panel : "")

    };

    const handleSubmitOrder = async (isAdminOverride = false) => {

        setIsAllSubmit(true)
        let ordersToSubmit = []
        let ordersToSave = []

        let isOrdersValid = true

        orders.map((order, index) => {
            let orderProducts = []

            order.products.map(product => {
                if(product.listPrice || product.listPrice == ''){
                    if(product.listPrice == ''){
                        isOrdersValid = false
                    }
                } else{
                    if(product.price == ''){
                        isOrdersValid = false
                    }
                }
                if(product._id !== process.env.REACT_APP_FUEL_ID){
                    if(product.amount > product.qtyOnHand){
                        isOrdersValid = false
                    }
                }
                if(product.amount > 0){
                    orderProducts.push(product)
                }
            })

            if(orderProducts.length > 0){
                ordersToSubmit.push({
                    ...order,
                    products : orderProducts,
                    status: 'Submitted',
                    ...orderFields[index]
                })
            } else {
                ordersToSave.push({
                    orderId : order._id,
                    products : orderProducts,
                    ...orderFields[index]
                })
            }
        })

        ordersToSubmit.forEach(order => {
            if(order.invoiceDate && order.shipDate && order.invoiceDate !== '' && order.shipDate !== ''){

            } else{
                isOrdersValid = false
                setInvalidSelectedOrders(oldInvalidSelectedOrders => [...oldInvalidSelectedOrders, order])
            }
        })

        setIsOrdersValid(isOrdersValid)

        if(isOrdersValid){
            if(ordersToSubmit.length > 0){
                // console.log("Orders to submit: ", ordersToSubmit)
                await submitOrders(user.id, ordersToSubmit, accessToken).then(response =>{
                }).catch(e=>{console.log(e)})
            }
            if(ordersToSave.length > 0){
                // console.log("Orders to save: ", ordersToSave)
                await saveOrders(ordersToSave, accessToken).then(response =>{
                }).catch(e=>{console.log(e)})
            }
            setIsSubmitCompleted(true)
            setDialogMessage("Your order has been submited.")
            handleClickOpen()
        } else{
            if(isAdminOverride){
                if(ordersToSubmit.length > 0){
                    // console.log("Orders to submit: ", ordersToSubmit)
                    await submitOrders(user.id, ordersToSubmit, accessToken).then(response =>{
                    }).catch(e=>{console.log(e)})
                }
                if(ordersToSave.length > 0){
                    // console.log("Orders to save: ", ordersToSave)
                    await saveOrders(ordersToSave, accessToken).then(response =>{
                    }).catch(e=>{console.log(e)})
                }
                setDialogMessage("Your order has been submited.")
                setIsSubmitCompleted(true)
                setIsOrdersValid(true)
                setSelectedOrders([])
                handleClickOpen()
            } else{
                setDialogMessage("To submit, orders must have an invoice date and a ship date. All unit prices must be 0 or greater. " +
                                "Not enough items to fulfill an order, except for fuel, amount to order must be less than QoH. Review the orders.")
                handleClickOpen()
            }
        }
    }

    const handleSubmitSelectedOrders = async (isAdminOverride = false) => {

        setIsSelectedSubmit(true)
        let ordersToSubmit = []
        let isOrdersValid = true

        const ordersSelectedToSubmit = orders.filter(curr => selectedOrders.includes(curr._id))

        ordersSelectedToSubmit.map((order, index) => {
            let orderProducts = []
            const currOrderFields = orderFields.find(curr => curr._id == order._id)

            order.products.map(product => {
                if(product.listPrice || product.listPrice == ''){
                    if(product.listPrice == ''){
                        isOrdersValid = false
                    }
                } else{
                    if(product.price == ''){
                        isOrdersValid = false
                    }
                }
                if(product._id !== process.env.REACT_APP_FUEL_ID){
                    if(product.amount > product.qtyOnHand){
                        isOrdersValid = false
                    }
                }
                if(product.amount > 0){
                    orderProducts.push(product)
                }
            })

            if(orderProducts.length > 0){
                ordersToSubmit.push({
                    ...order,
                    products : orderProducts,
                    status: 'Submitted',
                    ...currOrderFields
                })
            } 
        })

        ordersToSubmit.forEach(order => {
            console.log("Validating orders: ", order)
            if(order.invoiceDate && order.shipDate && order.invoiceDate !== '' && order.shipDate !== ''){

            } else{
                isOrdersValid = false
                setInvalidSelectedOrders(oldInvalidSelectedOrders => [...oldInvalidSelectedOrders, order])
            }
        })

        setIsOrdersValid(isOrdersValid)

        if(isOrdersValid && ordersToSubmit.length > 0){
            
            await submitOrders(user.id, ordersToSubmit, accessToken).then(response =>{
            }).catch(e=>{console.log(e)})
            setIsSubmitCompleted(true)
            setDialogMessage("Your order has been submited.")
            handleClickOpen()
        } else{
            if(isAdminOverride){
                await submitOrders(user.id, ordersToSubmit, accessToken).then(response =>{
                    setIsSubmitCompleted(true)
                    setDialogMessage("Your order has been submited.")
                    setIsOrdersValid(true)
                    setSelectedOrders([])
                    handleClickOpen()
                    
                }).catch(e=>{console.log(e)})
            } else{
                setDialogMessage("To submit, orders must have an invoice date and a ship date. All unit prices must be 0 or greater. " +
                                "Not enough items to fulfill an order, except for fuel, amount to order must be less than QoH. Review the orders.")
                handleClickOpen()
            }
        }
    }

    const handleSubmitSingleBackOrder = (order) => {
        setCurrOrder(order)
        setMessageBO("Are you sure you want to submit as Back Order.")
        setCurrOrderStatus("Back Order")
        setOpenBO(true)
    }

    const handleSubmitSingleQuote = (order) => {
        setCurrOrder(order)
        setMessageBO("Are you sure you want to submit as Quote.")
        setCurrOrderStatus("Quote")
        setOpenBO(true)
    }

    const handleSubmitSingleOrder = async (order, status, isAdminOverride = false) => {

        let isOrdersValid = true
        let ordersToSubmit = []

        let currOrderFields = orderFields.find(curr => curr._id == order._id)
        let orderProducts = []

        let invalidMessage = ""

        order.products.map(product => {
            
            if(product._id !== process.env.REACT_APP_FUEL_ID){
                if(product.amount > product.qtyOnHand){
                    isOrdersValid = false
                    invalidMessage += "Not enough items to fulfill order, except for fuel, amount to order must be less than QoH. "
                    setCurrOrder(order)
                }
            }
            if(product.listPrice || product.listPrice == ''){
                if(product.listPrice == ''){
                    isOrdersValid = false
                    invalidMessage += "All unit price must be 0 or greater. "
                    setCurrOrder(null)
                }
            } else{
                if(product.price == ''){
                    isOrdersValid = false
                    invalidMessage += "All unit price must be 0 or greater. "
                    setCurrOrder(null)
                }
            }

            if(product.amount > 0){
                orderProducts.push(product)
            }
        })

        if(orderProducts.length > 0){
            ordersToSubmit.push({
                ...order,
                status: status,
                products : orderProducts,
                ...currOrderFields
            })
        } 

        if(ordersToSubmit[0].invoiceDate && ordersToSubmit[0].shipDate && ordersToSubmit[0].invoiceDate !== '' && ordersToSubmit[0].shipDate !== ''){

        } else{
            isOrdersValid = false
            invalidMessage += "To submit, order must have an invoice date and a ship date. "
            setCurrOrder(null)
        }

        setIsOrdersValid(isOrdersValid)

        if(isOrdersValid){
            await submitOrders(user.id, ordersToSubmit, accessToken).then(response =>{
                setIsSubmitCompleted(true)
                setDialogMessage("Your order has been submited.")
                setCurrOrder(null)
                setIsOrdersValid(true)
                handleClickOpen()
                
            }).catch(e=>{console.log(e)})
        } else{
            if(isAdminOverride){
                await submitOrders(user.id, ordersToSubmit, accessToken).then(response =>{
                    setIsSubmitCompleted(true)
                    setDialogMessage("Your order has been submited.")
                    setCurrOrder(null)
                    setIsOrdersValid(true)
                    handleClickOpen()
                    
                }).catch(e=>{console.log(e)})
            } else{
                setDialogMessage(invalidMessage)
                handleClickOpen()
            }
        }
    }

    const handleSaveOrders = () => {
        let ordersToSubmit = []

        orders.map((order, index) => {
            let orderProducts = []

            order.products.map(product => {
                if(product.listPrice || product.listPrice == ''){
                
                    if(product.listPrice == ''){
                    
                        isOrdersValid = false
                    }
                } else{
                    
                    if(product.price == ''){
                        
                        isOrdersValid = false
                    }
                }
                if(product.amount > 0){
                    orderProducts.push(product)
                }
            })
            
            ordersToSubmit.push({
                orderId : order._id,
                products : orderProducts,
                ...orderFields[index]
            })
        })
        
        console.log(ordersToSubmit)

        saveOrders(ordersToSubmit, accessToken).then(response =>{
            window.location.reload()
        }).catch(e=>{console.log(e)})
    }

    useEffect(() =>{
        fetchorders(user.id,accessToken).then(response =>{
            // console.log("Orders", response.data)
            let confirmationOrders = response.data.filter(order => order.products.length > 0)

            let orderFields = []
            if(process.env.REACT_APP_BIOWARE_TENANT == 'biowarebuyer'){

                confirmationOrders.forEach((order, index) => {
                    orderFields.push({
                        _id: order._id,
                        comment: order.comment ? order.comment : '',
                        shipDate: order.shipDate ? order.shipDate : '',
                        invoiceDate: order.invoiceDate ? order.invoiceDate : '',
                        customerPo : order.customerPo ? order.customerPo : '',
                        shipVia : order.shipVia ? order.shipVia : order.company[0].shipVia,
                    })

                    const fuelItem = order.products.find(product => product._id == process.env.REACT_APP_FUEL_ID)
                    if(fuelItem){
                        let products = order.products.filter(product => product._id !== fuelItem._id)

                        products.push(fuelItem)

                        const newOrder = {
                            ...order,
                            products : products
                        }

                        confirmationOrders[index] = newOrder
                    }
                })
                
                
            } else {
                confirmationOrders.forEach((order, index) => {
                    orderFields.push({
                        _id: order._id,
                        comment: order.comment ? order.comment : '',
                        shipDate: order.shipDate ? order.shipDate : '',
                        invoiceDate: order.invoiceDate ? order.invoiceDate : '',
                        customerPo : order.customerPo ? order.customerPo : '',
                        shipVia : order.shipVia ? order.shipVia : order.company[0].shipVia,
                    })
                })
            }

            setOrderFields(orderFields)
            setOrders(confirmationOrders)

            if(confirmationOrders.length > 0){
                setIsOrdersFetched(true)
            } else {
                setIsCartEmpty(true)
            }
        })
    },[])

    useEffect(() =>{
        fetchorders(user.id,accessToken).then(response =>{
            // console.log("Orders", response.data)
            let confirmationOrders = response.data.filter(order => order.products.length > 0)
            let orderFields = []

            if(process.env.REACT_APP_BIOWARE_TENANT == 'biowarebuyer'){

                confirmationOrders.forEach((order, index) => {
                    orderFields.push({
                        _id: order._id,
                        comment: order.comment ? order.comment : '',
                        shipDate: order.shipDate ? order.shipDate : '',
                        invoiceDate: order.invoiceDate ? order.invoiceDate : '',
                        customerPo : order.customerPo ? order.customerPo : '',
                        shipVia : order.shipVia ? order.shipVia : order.company[0].shipVia,
                    })

                    const fuelItem = order.products.find(product => product._id == process.env.REACT_APP_FUEL_ID)
                    if(fuelItem){
                        let products = order.products.filter(product => product._id !== fuelItem._id)

                        products.push(fuelItem)

                        const newOrder = {
                            ...order,
                            products : products
                        }

                        confirmationOrders[index] = newOrder
                    }
                })
                
                
            } else {
                confirmationOrders.forEach((order, index) => {
                    orderFields.push({
                        _id: order._id,
                        comment: order.comment ? order.comment : '',
                        shipDate: order.shipDate ? order.shipDate : '',
                        invoiceDate: order.invoiceDate ? order.invoiceDate : '',
                        customerPo : order.customerPo ? order.customerPo : '',
                        shipVia : order.shipVia ? order.shipVia : order.company[0].shipVia,
                    })
                })
            }

            setOrderFields(orderFields)
            setOrders(confirmationOrders)

            if(confirmationOrders.length > 0){
                setIsOrdersFetched(true)
            } else {
                setIsCartEmpty(true)
            }
        })
    },[isOrderUpdated])

    

    const handleProductAmountChange = (orderId, productId, productAmount) => {
        let orderIndex = orders.findIndex(order => order._id === orderId)
        let order = orders.find(order => order._id === orderId)
        let newOrders = orders

        let products = order.products
        let productIndex = products.findIndex(product => product._id === productId)

        const oldTotalAmount = order.totalProductQty
        const oldTotalCost = order.totalCost
        let newTotalAmount = oldTotalAmount
        let newTotalCost = parseFloat(oldTotalCost)

        const product = products[productIndex]
        let productPrice = (product.listPrice || product.listPrice == '') ? product.listPrice : product.price
        if(product.isCatchWeight){
            productPrice = productPrice * product.avgWeight
            productPrice = Math.round( parseFloat(productPrice) * 1e2 ) / 1e2
        }

        if(productPrice > 0){
            if(product.amount < productAmount){
                newTotalCost += parseFloat(productPrice)
            } else {
                newTotalCost -= parseFloat(productPrice)
            }
        }
        if(product.amount < productAmount){
            newTotalAmount += 1
        } else {
            newTotalAmount -= 1
        }

        products[productIndex] = {
            ...products[productIndex],
            amount : productAmount,
        }

        const newOrder = {
            ...order,
            totalProductQty : newTotalAmount,
            totalCost : Math.round( parseFloat(newTotalCost) * 1e2 ) / 1e2,
            products : products
        }

        newOrders[orderIndex] = newOrder
        
        setOrders([...newOrders])
    }

    const handlePriceChange = (orderId, productId, newPrice) => {
        let orderIndex = orders.findIndex(order => order._id === orderId)
        let order = orders.find(order => order._id === orderId)
        let newOrders = orders

        let products = order.products
        let productIndex = products.findIndex(product => product._id === productId)
        const product = products[productIndex]

        let newPriceParsed = parseFloat(newPrice)
        let newEstimatedPrice = 0
        let oldEstimatedPrice = 0

        const oldPrice = (product.listPrice || product.listPrice == '') ? product.listPrice : product.price
        const oldTotalCost = order.totalCost
        
        let newTotalCost = parseFloat(oldTotalCost)

        if(product.isCatchWeight){
            oldEstimatedPrice = oldPrice * product.avgWeight
            newEstimatedPrice = newPriceParsed * product.avgWeight

            oldEstimatedPrice = Math.round( parseFloat(oldEstimatedPrice) * 1e2 ) / 1e2
            newEstimatedPrice = Math.round( parseFloat(newEstimatedPrice) * 1e2 ) / 1e2
        }
        
        if(oldPrice == ''){
            newTotalCost -= parseFloat(product.amount * 0)
        } else{
            if(product.isCatchWeight){
                newTotalCost -= parseFloat(product.amount * oldEstimatedPrice)
            } else{
                newTotalCost -= parseFloat(product.amount * oldPrice)
            }
        }   

        if(!isNaN(newPriceParsed)){
            if(product.isCatchWeight){
                newTotalCost += parseFloat(product.amount * newEstimatedPrice)
            } else{
                newTotalCost += parseFloat(product.amount * newPriceParsed)
            }
        }  

        if(isNaN(newPriceParsed)){
            newPriceParsed = ""
        }

        if(product.listPrice || product.listPrice == ''){
            products[productIndex] = {
                ...products[productIndex],
                listPrice : newPriceParsed,
                estimatedPrice: newEstimatedPrice
            }
        } else{
            products[productIndex] = {
                ...products[productIndex],
                price : newPriceParsed,
                estimatedPrice: newEstimatedPrice
            }
        }

        const newOrder = {
            ...order,
            totalCost : Math.round( parseFloat(newTotalCost) * 1e2 ) / 1e2,
            products : products
        }

        newOrders[orderIndex] = newOrder
        
        setOrders([...newOrders])
    }

    function handleRemove(orderId, productId) {
        let orderIndex = orders.findIndex(order => order._id === orderId)
        let order = orders.find(order => order._id === orderId)
        let newOrders = orders

        const productToRemove = order.products.find(product => product._id == productId)
        const productAmount = productToRemove.amount
        let productPrice = (productToRemove.listPrice || productToRemove.listPrice == '') ? productToRemove.listPrice : productToRemove.price

        if(productPrice == ''){
            productPrice = 0
        }

        if(productToRemove.isCatchWeight){
            productPrice = productPrice * productToRemove.avgWeight
            productPrice = Math.round( parseFloat(productPrice) * 1e2 ) / 1e2
        }

        let newTotalCost = parseFloat(order.totalCost)
        newTotalCost -= productAmount * productPrice

        let newTotalAmount = order.totalProductQty
        newTotalAmount -= productAmount

        let products = order.products.filter(product => product._id !== productId)

        const newOrder = {
            ...order,
            totalProductQty : newTotalAmount,
            totalCost : Math.round( parseFloat(newTotalCost) * 1e2 ) / 1e2,
            products : products
        }
        
        if(products.length > 0){
            newOrders[orderIndex] = newOrder
        } else{
            newOrders = orders.filter(currOrder => currOrder._id !== orderId)
        }
        
        setOrders([...newOrders])
    }

    const handleToken = (totalAmount, token) => {
        try {
            axios.post(process.env.REACT_APP_API_URL + "/stripe/pay", {
                token: token.id,
                amount: totalAmount
            }).then((result) => {
                // Run code after successful payment
                handleSubmitOrder()
            })
        } catch (error) {
            console.log(error)
        }
    }

    const tokenHandler = (token) => {
        handleToken(10, token)


    }

    const handleCommentChange = (e, index) => {
        
        let newOrderFields = [...orderFields]
        newOrderFields[index].comment = e.target.value

        setOrderFields(newOrderFields)
    }

    const handleCustomerPoChange = (e, index) => {
        
        let newOrderFields = [...orderFields]
        newOrderFields[index].customerPo = e.target.value

        setOrderFields(newOrderFields)
    }

    const handleDefaultDateChange = (e) => {
        
        let newOrderFields = [...orderFields]

        for(let i = 0; i < newOrderFields.length; i++){
            newOrderFields[i].invoiceDate = e.target.value
            newOrderFields[i].shipDate = e.target.value
        }

        setOrderFields(newOrderFields)
    }

    const handleInvoiceDateChange = (e, index) => {
        
        let newOrderFields = [...orderFields]
        newOrderFields[index].invoiceDate = e.target.value

        if(user.tenant == process.env.REACT_APP_WAHMEY_TENANT){
            newOrderFields[index].shipDate = e.target.value
        }

        setOrderFields(newOrderFields)
    }

    const handleShipDateChange = (e, index) => {
        
        let newOrderFields = [...orderFields]
        newOrderFields[index].shipDate = e.target.value

        if(user.tenant == process.env.REACT_APP_WAHMEY_TENANT){
            newOrderFields[index].invoiceDate = e.target.value
        }

        setOrderFields(newOrderFields)
    }

    const handleShipViaChange = (e, index) => {
        console.log("Changing ship via to: ", e.target.value)
        let newOrderFields = [...orderFields]
        newOrderFields[index].shipVia = e.target.value

        setOrderFields(newOrderFields)
    }

    const handleOrderClick = (order) => {
        console.log("Order clicked: ", order)
        
        setClickedOrder(order)
        setIsOderSummaryClicked(true)
    }
    
    const handleOrderSelect = (order) => {
        
        const orderFound = selectedOrders.find(currOrderId => currOrderId == order._id)
        if(orderFound){
            const newSelectedOrders = selectedOrders.filter(currOrderId => currOrderId != order._id)
            setSelectedOrders(newSelectedOrders)
        } else{
            setSelectedOrders(oldSelectedOrders => [...oldSelectedOrders, order._id])
        }
    }

    console.log("Orders: ", orders)
    return (
        <>
        
            {isOrdersFetched ? 
                isOrderSummaryClicked ? 
                <OrderSummary currOrder={clickedOrder} setIsOderSummaryClicked={setIsOderSummaryClicked} 
                    setIsOrderUpdated={setIsOrderUpdated} isOrderUpdated={isOrderUpdated} />
                :
                <div>
                    {orders.map((order, index)=> (
                        <ExpansionPanel square expanded={expanded === 'panel' + index} onChange={handleAccordionChange('panel' + index)}>
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon/>}
                                aria-controls={'panel-content-' + index}
                                id={'panel-header-' + index}
                                >
                                <label className="checkbox checkbox-lg checkbox-single flex-shrink-0 m-0 mx-4">
                                    <input type="checkbox" checked={selectedOrders.find(currOrder => currOrder == order._id)} name="" onClick={() => handleOrderSelect(order)} />
                                    <span></span>
                                </label>
                                <Typography className={classes.heading}>
                                    {`Order #${order?.orderNumber}`}
                                </Typography>
                                <Typography className={classes.secondaryHeading}>{order?.companyName}</Typography>
                                
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                <div className="flex-row-fluid ml-lg-8">
                                    <div className="">
                                        <div className="card-body p-0">
                                            <div className="row justify-content-center py-8 px-8 py-md-27 px-md-0">
                                                <div className="col-md-10">
                                                    <div className="col-sm bg-light-primary px-6 py-4 rounded-xl mr-7">
                                                        <div className="table-responsive">
                                                            <table className="table table-borderless">
                                                                <thead>
                                                                    <tr>
                                                                        {
                                                                            user.isSeller ?
                                                                                <th className="pl-4 font-weight-boldest text-uppercase">Customer:</th>
                                                                            :
                                                                                <th className="pl-4 font-weight-boldest text-uppercase">Company:</th>
                                                                        }
                                                                        <th className="font-weight-boldest text-uppercase">Ship To:</th>
                                                                        {
                                                                            user.isSeller ?
                                                                                <th className="font-weight-boldest text-uppercase">Sales Rep:</th>
                                                                                :
                                                                                <th className="font-weight-boldest text-uppercase">Ordering To:</th>
                                                                        }

                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr className="font-weight-boldest">
                                                                        <td className="pl-4 d-flex">
                                                                            {/* <h6 className="font-weight-bolder mb-3">Delivery Address:</h6> */}
                                                                            <div className="text-dark-50">
                                                                                <div>{order.company[0].businessName}</div>
                                                                                {/*<div>Melbourne 3000, VIC, Australia</div>*/}
                                                                            </div>
                                                                        </td>
                                                                        <td >
                                                                            {/* <h6 className="font-weight-bolder mb-3">Delivery Address:</h6> */}
                                                                            <div className="text-dark-50">
                                                                                <div>{order.location[0].locationName}</div>
                                                                                <div>{order.location[0].locationAddress1} {order.location[0].locationAddress2}</div>
                                                                                <div>{order.location[0].locationCity}, {order.location[0].locationZipcode}</div>
                                                                                {/*<div>Melbourne 3000, VIC, Australia</div>*/}
                                                                            </div>
                                                                        </td>
                                                                        {
                                                                            user.isSeller ?
                                                                                <td className="text-dark-50">{order.user[0].fullName}</td>
                                                                                :
                                                                                <td className="text-dark-50">{order.supplier[0].entityName}</td>
                                                                        }
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <button type="button" className="my-3 btn btn-primary w-100" onClick={() => handleOrderClick(order)} >Edit Order</button>
                                                    <div>
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={6}>
                                                                <div className="form-group fv-plugins-icon-container">
                                                                    <label htmlFor={"orderComent"}>Comment</label>
                                                                    <textarea
                                                                        value={orderFields[index].comment}
                                                                        onChange={(e) => handleCommentChange(e, index)}
                                                                        className={`form-control form-control-solid h-auto py-2 px-6`}
                                                                        placeholder={"Comment"}
                                                                        rows="3"
                                                                    />
                                                                </div>
                                                                <div className="form-group fv-plugins-icon-container">
                                                                    <label htmlFor={"customerPo"}>Customer PO</label>
                                                                    <input
                                                                        value={orderFields[index].customerPo}
                                                                        onChange={(e) => handleCustomerPoChange(e, index)}
                                                                        className={`form-control form-control-solid h-auto py-2 px-6`}
                                                                        placeholder={"Customer PO"}
                                                                        type="text"
                                                                    />
                                                                </div>
                                                                <FormControl variant="filled" style={{ width: "100%" }}>
                                                                    <InputLabel>Ship Via</InputLabel>
                                                                    <Select
                                                                        MenuProps={{ autoFocus: false }}
                                                                        value={orderFields[index].shipVia}
                                                                        style={{ minWidth: "200px" }}
                                                                        input={<FilledInput name="shipVia" id="shipVia"
                                                                            onChange={(element) => {
                                                                                handleShipViaChange(element, index);
                                                                                // setCompany(companies.find(company => company._id == element.target.value))
                                                                                // setLocations(companies.find(company => company._id == element.target.value).locations)
                                                                            } }
                                                                            type="text"
                                                                            className="form-control form-control-solid h-auto" />}>

                                                                        <MenuItem key={order.company[0].shipVia} value={order.company[0].shipVia}>{order.company[0].shipVia == "Our Truck" ? "Delivery" : order.company[0].shipVia == "HOME" ? "Pick Up" : order.company[0].shipVia}</MenuItem>
                                                                        {
                                                                            order.supplier[0].shipViaOptions.map((option,index) =>(
                                                                                order.company[0]?.shipVia == option ?
                                                                                null
                                                                                :
                                                                                <MenuItem key={option} value={option}>{option == "Our Truck" ? "Delivery" : option == "HOME" ? "Pick Up" : option}</MenuItem>
                                                                            ))
                                                                        }
                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <div className="form-group fv-plugins-icon-container">
                                                                    <label htmlFor={"invoiceDate"}>Invoice Date</label>
                                                                    <Input
                                                                        type="date"
                                                                        className={`form-control form-control-solid h-auto py-5 px-6`}
                                                                        name="personalBirthDate"
                                                                        value={orderFields[index].invoiceDate}
                                                                        onChange={(e) => handleInvoiceDateChange(e, index)}
                                                                    />
                                                                </div>
                                                                <div className="form-group fv-plugins-icon-container">
                                                                    <label htmlFor={"shipDate"}>Ship Date</label>
                                                                    <Input
                                                                        type="date"
                                                                        className={`form-control form-control-solid h-auto py-5 px-6`}
                                                                        name="personalBirthDate"
                                                                        value={orderFields[index].shipDate}
                                                                        onChange={(e) => handleShipDateChange(e, index)}
                                                                    />
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                    <div className="pb-5" data-wizard-type="step-content">
                                                        {/*<h4 className="mb-10 font-weight-bold text-dark">Review your Order and Submit</h4>*/}
                                                        {/* <h6 className="font-weight-bolder mb-3">Delivery Address:</h6>
                                                        <div className="text-dark-50 line-height-lg">
                                                            <div>{order.company[0].businessName}</div>
                                                            <div>{order.location[0].locationAddress}</div>
                                                            <div>{order.location[0].locationCity}, {order.location[0].locationZipcode}</div> */}
                                                            {/*<div>Melbourne 3000, VIC, Australia</div>*/}
                                                        {/* </div> */}
                                                        {/* <div className="separator separator-dashed my-5"></div> */}

                                                        {/* <h6 className="font-weight-bolder mb-3">Order Details:</h6>
                                                        <div>{order.supplier[0].entityName}</div> */}
                                                        <div className="text-dark-50 line-height-lg">
                                                            <div className="table-responsive">
                                                                <table className="table">
                                                                    <thead>
                                                                        <tr className='border-bottom'>
                                                                            <th className="pl-0 font-weight-bold text-muted text-uppercase">Ordered
                                                                                Items
                                                                            </th>
                                                                            {/* <th className="text-center font-weight-bold text-muted text-uppercase">SKU</th> */}
                                                                            {
                                                                                user.tenant == process.env.REACT_APP_WAHMEY_TENANT ?
                                                                                    <th className="text-center font-weight-bold text-muted text-uppercase">Avg Weight</th>
                                                                                :
                                                                                    null
                                                                            }
                                                                            
                                                                            <th className="text-center font-weight-bold text-muted text-uppercase">Unit
                                                                                Price
                                                                            </th>
                                                                            <th className="text-center font-weight-bold text-muted text-uppercase">QoH</th>
                                                                            <th className="text-center font-weight-bold text-muted text-uppercase">Qty To Order</th>
                                                                            <th className="text-center font-weight-bold text-muted text-uppercase">Item
                                                                                Price
                                                                            </th>
                                                                            <th className="text-center font-weight-bold text-muted text-uppercase">Total
                                                                                Amount
                                                                            </th>
                                                                            <th className="text-right pr-0 font-weight-bold text-muted text-uppercase">Remove</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {order.products.map(product => (
                                                                        <tr className="font-weight-boldest border-bottom-0">
                                                                            <td className="border-top-0 pl-0 py-2 align-items-center">
                                                                                {/* <div className="symbol symbol-40 flex-shrink-0 mr-4 bg-light">
                                                                                    <div className="symbol-label"
                                                                                        style={{backgroundImage: "url(" + product.imagePath + ")"}}></div>
                                                                                </div> */}
                                                                                
                                                                                <span className="text-dark-75 font-weight-bolder d-block text-center font-size-lg">
                                                                                    {product.name}
                                                                                </span>
                                                                                <span className="text-muted font-weight-bold d-block text-center">
                                                                                    {product.sku}
                                                                                </span>
                                                                            </td>
                                                                            {/* <td className="border-top-0 text-center py-2 align-items-center">
                                                                                {product.sku}
                                                                            </td> */}
                                                                            {
                                                                                user.tenant == process.env.REACT_APP_WAHMEY_TENANT ?
                                                                                    <td className="border-top-0 text-center py-2 align-items-center">
                                                                                        {
                                                                                            product.isCatchWeight ? 
                                                                                                product.avgWeight
                                                                                            :
                                                                                                "N/A"
                                                                                        }
                                                                                    </td>
                                                                                :
                                                                                    null
                                                                            }
                                                                            
                                                                            <td className="border-top-0 text-right py-2 align-middle">{product.listPrice || product.listPrice == '' ? 
                                                                                    
                                                                                    <input
                                                                                        value={product.listPrice}
                                                                                        onChange={(e) => handlePriceChange(order._id, product._id, e.target.value)}
                                                                                        className={`form-control form-control-solid h-auto py-2 px-6`}
                                                                                        type="number"
                                                                                        min="0"
                                                                                        style={{minWidth:"125px"}}
                                                                                    />
                                                                                    :
                                                                                    <input
                                                                                        value={product.price}
                                                                                        onChange={(e) => handlePriceChange(order._id, product._id, e.target.value)}
                                                                                        className={`form-control form-control-solid h-auto py-2 px-6`}
                                                                                        type="number"
                                                                                        min="0"
                                                                                        style={{minWidth:"125px"}}
                                                                                    />
                                                                                }
                                                                            </td>
                                                                            <td className="border-top-0 text-center py-2 align-items-center">
                                                                                {product.qtyOnHand}
                                                                            </td>
                                                                            <td className="border-top-0 text-center py-2 align-items-center" style={{minWidth:"125px"}}>
                                                                                <div className="align-middle">
                                                                                    <button className="btn btn-xs btn-light-primary btn-icon mr-2" disabled={product.amount === 0} onClick={() => {
                                                                                        handleProductAmountChange(order._id, product._id, product.amount - 1)
                                                                                    }}>
                                                                                        <i className="ki ki-minus icon-xs"></i>
                                                                                    </button>
                                                                                    <span className="mr-2 font-weight-bolder align-middle">{product.amount}</span>
                                                                                    <button className="btn btn-xs btn-light-primary btn-icon mr-2" onClick={() => {
                                                                                        handleProductAmountChange(order._id, product._id, product.amount + 1)
                                                                                    }} >
                                                                                        <i className="ki ki-plus icon-xs"></i>
                                                                                    </button>
                                                                                </div>
                                                                            </td>
                                                                            <td className="border-top-0 text-center py-2 align-items-center">
                                                                                {
                                                                                    product.isCatchWeight ? 
                                                                                        product.estimatedPrice  
                                                                                    : 
                                                                                        product.listPrice && product.listPrice !== '' ?
                                                                                            product.listPrice
                                                                                        :
                                                                                            product.price
                                                                                }
                                                                            </td>
                                                                            <td className="border-top-0 text-center py-2 align-items-center">
                                                                                {
                                                                                    product.isCatchWeight ? 
                                                                                        product.estimatedPrice * product.amount
                                                                                    : 
                                                                                        product.listPrice && product.listPrice !== '' ?
                                                                                            product.listPrice * product.amount
                                                                                        :
                                                                                            product.price * product.amount
                                                                                }
                                                                            </td>
                                                                            <td className="border-top-0 text-right py-2 align-middle">
                                                                                <InputAdornment position="end">
                                                                                    <IconButton  onClick={() => handleRemove(order._id, product._id)}
                                                                                    >
                                                                                    <span  className="center svg-icon svg-icon-md svg-icon-primary">
                                                                                        <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
                                                                                    </span>
                                                                                    </IconButton>
                                                                                </InputAdornment>
                                                                            </td>
                                                                            {/*<td className="text-primary border-top-0 pr-0 py-4 text-right align-middle">$449.00</td>*/}
                                                                        </tr>
                                                                    ))}
                                                                        <tr className="font-weight-boldest border-bottom-0">
                                                                            <td className="pl-0 py-4 d-flex align-items-center">Total</td>
                                                                            <td ></td>
                                                                            <td ></td>
                                                                            <td ></td>
                                                                            <td ></td>
                                                                            <td className="text-center py-4 align-middle">{order.totalProductQty}</td>
                                                                            <td ></td>
                                                                            <td className="text-center py-4 align-middle">{`$${order.totalCost}`}</td>

                                                                        </tr>
                                                                    {/*<tr className='border-top'>*/}
                                                                    {/*    <td colSpan="2"></td>*/}
                                                                    {/*    <td className="font-weight-bolder text-right">Subtotal</td>*/}
                                                                    {/*    <td className="font-weight-bolder text-right pr-0">$1538.00</td>*/}
                                                                    {/*</tr>*/}
                                                                    {/*<tr>*/}
                                                                    {/*    <td colSpan="2" className="border-0 pt-0"></td>*/}
                                                                    {/*    <td className="border-0 pt-0 font-weight-bolder text-right">Delivery*/}
                                                                    {/*        Fees*/}
                                                                    {/*    </td>*/}
                                                                    {/*    <td className="border-0 pt-0 font-weight-bolder text-right pr-0">$15.00</td>*/}
                                                                    {/*</tr>*/}
                                                                    {/*<tr>*/}
                                                                    {/*    <td colSpan="2" className="border-0 pt-0"></td>*/}
                                                                    {/*    <td className="border-0 pt-0 font-weight-bolder font-size-h5 text-right">Grand*/}
                                                                    {/*        Total*/}
                                                                    {/*    </td>*/}
                                                                    {/*    <td className="border-0 pt-0 font-weight-bolder font-size-h5 text-success text-right pr-0">$1553.00</td>*/}
                                                                    {/*</tr>*/}
                                                                    </tbody>
                                                                </table>

                                                            </div>
                                                        </div>
                                                        {/*<div className="separator separator-dashed my-5"></div>*/}
                                                        {/*<h6 className="font-weight-bolder mb-3">Delivery Service Type:</h6>*/}
                                                        {/*<div className="text-dark-50 line-height-lg">*/}
                                                        {/*    <div>Overnight Delivery with Regular Packaging</div>*/}
                                                        {/*    <div>Preferred Morning (8:00AM - 11:00AM) Delivery</div>*/}
                                                        {/*</div>*/}
                                                        <div className="row justify-content-between">
                                                            <div className="d-flex flex-wrap">
                                                                <button type="button" className="btn btn-success font-weight-bolder text-uppercase px-9 py-4 ml-5"
                                                                    data-wizard-type="action-submit"
                                                                    onClick={() =>{
                                                                        handleSubmitSingleOrder(order, "Submitted");
                                                                    }}>Submit
                                                                </button>
                                                            </div>
                                                            {
                                                                user.tenant == process.env.REACT_APP_WAHMEY_TENANT ?
                                                                    null
                                                                :
                                                                    <div className="d-flex">
                                                                        <button type="button" className="btn btn-info font-weight-bolder text-uppercase px-9 py-4 ml-5"
                                                                            data-wizard-type="action-submit"
                                                                            onClick={() =>{
                                                                                handleSubmitSingleBackOrder(order);
                                                                            }}>Submit as Back Order
                                                                        </button>
                                                                        <button type="button" className="btn btn-warning font-weight-bolder text-uppercase px-9 py-4 ml-5"
                                                                            data-wizard-type="action-submit"
                                                                            onClick={() =>{
                                                                                handleSubmitSingleQuote(order);
                                                                            }}>Submit as Quote
                                                                        </button>
                                                                    </div>
                                                            }
                                                            
                                                        </div>
                                                    </div>

                                                    {/*<div className="border-bottom w-100"></div>*/}
                                                    
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    ))}
                        
                            <div className="row justify-content-center py-8 px-8 py-md-27 px-md-0">
                                {/* <div className="mr-2">
                                    <button type="button" className="btn btn-light-primary font-weight-bolder text-uppercase px-9 py-4"
                                            data-wizard-type="action-prev">Previous
                                    </button>
                                </div> */}
                                <div>
                                    <button type="button" className="btn btn-success font-weight-bolder text-uppercase px-9 py-4 mr-5"
                                            data-wizard-type="action-submit"
                                            onClick={() =>{
                                                handleSaveOrders();
                                            }}>Update Cart
                                    </button>

                                    <button type="button" className="btn btn-success font-weight-bolder text-uppercase px-9 py-4 ml-5"
                                            data-wizard-type="action-submit"
                                            onClick={() =>{
                                                handleSubmitSelectedOrders();
                                            }}>Submit Selected Orders
                                    </button>
                                    
                                    <button type="button" className="btn btn-success font-weight-bolder text-uppercase px-9 py-4 ml-5"
                                            data-wizard-type="action-submit"
                                            onClick={() =>{
                                                handleSubmitOrder();
                                            }}>Submit ALL
                                    </button>
                                    {/* <Stripe
                                        stripeKey={ process.env.REACT_APP_STRIPE_PUBLIC }
                                        token={tokenHandler}
                                    >
                                        <button type="button" className="btn btn-success font-weight-bolder text-uppercase px-9 py-4 ml-5"
                                            data-wizard-type="action-submit"
                                            >Pay & Submit
                                        </button>
                                    </Stripe> */}
                                </div>
                            </div>
                    </div>
                    
                :
                    isCartEmpty ?
                        <div className="card card-custom gutter-b">
                            <div className="card-body p-0">
                                <div className="row justify-content-center px-8 py-md-27 px-md-0">
                                    <div className="col-md-10">
                                        <div className="col-sm bg-light-primary px-6 py-4 rounded-xl mr-7">
                                            <div className="text-center mb-4">
                                                <span className="card-label font-weight-bolder text-dark text-center font-size-lg">Shopping Cart</span>
                                            </div>
                                            <div className="text-center">
                                                <span className="text-dark-75 mt-3 font-weight-bold font-size-lg">Shopping Cart is empty!</span>
                                            </div >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    :
                        null

            }
            
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Order Confirmation"}</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {dialogMessage}
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        OK
                    </Button>
                    {
                        user.isBuyerAdmin && currOrder ? 
                        <Button onClick={() => handleSubmitSingleOrder(currOrder, "Submitted", true)} color="primary" autoFocus>
                            Force Submit
                        </Button>
                        :
                        null
                    }
                    {
                        user.isBuyerAdmin && invalidSelectedOrders.length < 1 && !isOrdersValid && isSelectedSubmit ? 
                            <Button onClick={() => handleSubmitSelectedOrders(true)} color="primary" autoFocus>
                                Force Submit
                            </Button>
                        :
                            null
                    }
                    {
                        user.isBuyerAdmin && invalidSelectedOrders.length < 1 && !isOrdersValid && isAllSubmit ? 
                            <Button onClick={() => handleSubmitOrder(true)} color="primary" autoFocus>
                                Force Submit All
                            </Button>
                        :
                            null
                    }

                    </DialogActions>
                </Dialog>
                <Dialog
                    open={openBO}
                    onClose={handleCloseBO}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{"Warning"}</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {messageBO}
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleCloseBO} color="primary" autoFocus>
                        Cancel
                    </Button>
                    <Button onClick={() => handleSubmitSingleOrder(currOrder, currOrderStatus, true)} color="primary" autoFocus>
                        Submit
                    </Button>
                    </DialogActions>
                </Dialog>
            {/*<div className="row justify-content-center py-8 px-8 py-md-10 px-md-0">*/}
            {/*    <div className="col-md-10">*/}
            {/*        <div className="d-flex justify-content-between">*/}
            {/*            <button type="button" className="btn btn-light-primary font-weight-bold"*/}
            {/*                    onClick="window.print();">Download Order Details*/}
            {/*            </button>*/}
            {/*            <button type="button" className="btn btn-primary font-weight-bold"*/}
            {/*                    onClick="window.print();">Place Order*/}
            {/*            </button>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </>

    );
}

export default injectIntl(ConfirmationPage);