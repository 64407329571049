import React, {useEffect, useState, useRef} from "react";
// import { connect } from "react-redux";
import {injectIntl} from "react-intl";
import {useSelector} from "react-redux";
import {Card, CardBody, CardHeader, CardHeaderToolbar, CardFooter} from "../../../../_metronic/_partials/controls";
import { 
    getRoutinSupplierInfo, 
    getActiveRouteRecords, 
    patchRouteRecords, 
    submitRouteRecords, 
    patchRouteRecordForceQueue, 
    postSpecialRouteRecord ,
    patchDisableSpecialRouteRecord,
    deleteRouteRecord
} from "./routesCrud";
import {
    Button,
    Dialog, DialogActions, DialogContent, DialogContentText,
    DialogTitle,
    FilledInput,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    InputAdornment,
} from "@material-ui/core";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {DateTime} from 'luxon'
import OrderDetails from "../Orders/OrderDetails";
import RouteCasesDetail from "./RouteCasesDetail";
// import e from "express";

function RoutingPage({ setIsRouting }) {

    const [order, setOrder] = useState({})
    const [selectedRecordId, setSelectedRecordId] = useState(null)
    const [supplierInfo, setSupplierInfo] = useState({})
    const [routeRecords, setRouteRecords] = useState([])
    const [selectedRouteRecords, setSelectedRouteRecords] = useState([])
    const [selectedRouteRecordOrders, setSelectedRouteRecordOrders] = useState([])
    const [routeCasesPerCategoryWithProducts, setRouteCasesPerCategoryWithProducts] = useState([])
    const [routeCasesFavoriteProducts, setRouteCasesFavoriteProducts] = useState([])
    const [selectedReviewRouteIndex, setSelectedReviewRouteIndex] = useState("")
    const [selectedCasesRoute, setSelectedCasesRoute] = useState("");
    const [isCutOffTimeActive, setIsCutOffTimeActive] = useState(false)
    const [isOrderEdit, setIsOrderEdit] = useState(false)
    const [isOrderUpdated, setIsOrderUpdated] = useState(false)
    const [isCasesReviewUpdated, setIsCasesReviewUpdated] = useState(false)
    const [isRouteCasesDetail, setIsRouteCasesDetail] = useState(false)
    const [open, setOpen] = useState(false);
    const [openInfo, setOpenInfo] = useState(false);
    const [dialogMessage, setDialogMessage] = useState("");
    const [dialogInfoMessage, setDialogInfoMessage] = useState("");
    const [isTotalCasesGRTTruckCapacity, setIsTotalCasesGRTTruckCapacity] = useState(false)
    const [isValidSubmit, setIsValidSubmit] = useState(false)
    const [isRouteRecordsFetchFinished, setIsRouteRecordsFetchFinished] = useState(false)
    const [isActionsDisabled, setIsActionsDisabled] = useState(false)

    const initialRender = useRef(true);
    const initialRender2 = useRef(true);

    const { accessToken } = useSelector(
        ({auth}) => ({
            accessToken: auth.authToken,
        })
    )

    let initialFilter = {
        filter: {
            
        },
        sortOrder: "asc",
        sortField: "orderNumber",
        pageNumber: 1,
        pageSize: 10
    }

    const handleOrderClick = (order, recordId) => {
        setOrder(order)
        setSelectedRecordId(recordId)
        setIsOrderEdit(true)
    }

    function handleClose(isRefresh = false) {
        setOpen(false);
        
        if(isRefresh){
            if(selectedRouteRecords.length === routeRecords.length){
                window.location.reload()
            } else{
                setIsOrderUpdated(!isOrderUpdated)
            }
        }
    }

    function handleInfoAlert(infoMessage) {
        setOpenInfo(true);
        setDialogInfoMessage(infoMessage)
    }

    function handleInfoClose() {
        setOpenInfo(false);
        setDialogInfoMessage("")
        setIsActionsDisabled(false)
    }

    const fetchOrdersToRoute = async (queryParams, isUpdateCases = false) => {
        getActiveRouteRecords(queryParams, accessToken).then(response => {

            console.log("Curr route records: ", response.data.data)
            setRouteRecords(response.data.data)
            setIsRouteRecordsFetchFinished(true)

            console.log("Brrr")

            if(isUpdateCases){
                handleRouteCasesReviewUpdate(response.data.data)
            }

        }).catch(error => console.log(error))
    }

    useEffect(() => {
        getRoutinSupplierInfo(accessToken).then(res => {
            // TODO: If cut-off time is configured for same day as route, check if cut-off time has passed.

            const timeZone = 'America/Puerto_Rico'
            const luxonTodayDate = DateTime.now().setZone(timeZone)
            const currCutOffTimeHour = res.data.cut_off_time

            if(luxonTodayDate.c.hour < currCutOffTimeHour){

                setIsCutOffTimeActive(true)
            }

            setSupplierInfo(res.data)
        }).catch(err => {
            console.log("Err: ", err)
        })
        fetchOrdersToRoute(initialFilter)
    },[])

    useEffect(() => {
        if (initialRender2.current) {
            initialRender2.current = false;
        } else {
            setIsRouteRecordsFetchFinished(false)
            fetchOrdersToRoute(initialFilter)
            setSelectedRouteRecords([])
            // setDialogMessage("")
        }

    },[ isOrderUpdated ])

    useEffect(() => {
        if (initialRender.current) {
            initialRender.current = false;
        } else {
            
            fetchOrdersToRoute(initialFilter, true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCasesReviewUpdated])

    const onDragEnd = result => {
        const { destination, source, draggableId, type } = result;
    
        if (!destination) {
            return;
        }
    
        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }
    
        const sourceRoute = routeRecords.find(record => record._id == source.droppableId);
        const destinationRoute = routeRecords.find(record => record._id == destination.droppableId);
    
        if (sourceRoute === destinationRoute) {
            let newRouteRecordOrders = [...sourceRoute.routeRecordOrders]
            const currOrder = newRouteRecordOrders.find(order => order._id == draggableId)

            newRouteRecordOrders.splice(source.index, 1);
            newRouteRecordOrders.splice(destination.index, 0, currOrder);
        
            const newRouteRecord = {
                ...sourceRoute,
                routeRecordOrders: newRouteRecordOrders,
            };

            const sourceRouteIndex = routeRecords.findIndex(record => record._id == source.droppableId);
            let newRouteRecords = [
                ...routeRecords,
            ]

            newRouteRecords[sourceRouteIndex] = newRouteRecord
            setRouteRecords(newRouteRecords)
            return;
        }

        // moving from one list to another
        // TODO: update total cases on route record corresponding to moved order.
        let newSourceRouteRecordOrders = sourceRoute.routeRecordOrders;
        const currOrder = newSourceRouteRecordOrders.find(order => order._id == draggableId)

        const currOrderTotalCases = currOrder.totalProductQty
        const newSourceRouteTotalCases = sourceRoute.totalCases - currOrderTotalCases
        const newDestinationRouteTotalCases = destinationRoute.totalCases + currOrderTotalCases

        newSourceRouteRecordOrders.splice(source.index, 1);
        
        
        const newSourceRouteRecord = {
            ...sourceRoute,
            totalCases: newSourceRouteTotalCases,
            routeRecordOrders: newSourceRouteRecordOrders,
        };

        let newDestinationRouteRecordOrders = destinationRoute.routeRecordOrders;

        newDestinationRouteRecordOrders.splice(destination.index, 0, currOrder);
        
        const newDestinationRouteRecord = {
            ...destinationRoute,
            totalCases: newDestinationRouteTotalCases,
            routeRecordOrders: newDestinationRouteRecordOrders,
        };

        const sourceRouteIndex = routeRecords.findIndex(record => record._id == source.droppableId);
        const destinationRouteIndex = routeRecords.findIndex(record => record._id == destination.droppableId);
        let newRouteRecords = [
            ...routeRecords,
        ]

        

        newRouteRecords[sourceRouteIndex] = newSourceRouteRecord
        newRouteRecords[destinationRouteIndex] = newDestinationRouteRecord
    
        setRouteRecords(newRouteRecords)
    };

    const handleDriverChange = (element, routeIndex) => {
        const currRouteRecord = routeRecords[routeIndex]

        let newRouteRecords = [...routeRecords]
        newRouteRecords[routeIndex].selectedDriver = element.target.value

        const recordFound = selectedRouteRecords.find(record => record._id == currRouteRecord._id)
        if(recordFound){
            const newRecord = {
                ...recordFound,
                selectedDriver: element.target.value
            }

            let newSelectedRouteRecords = selectedRouteRecords.filter(record => record._id != currRouteRecord._id)
            newSelectedRouteRecords.push(newRecord)
            setSelectedRouteRecords(newSelectedRouteRecords)
        }

        setRouteRecords(newRouteRecords)
    }

    const handleTruckChange = (element, routeIndex) => {
        const currRouteRecord = routeRecords[routeIndex]

        let newRouteRecords = [...routeRecords]
        newRouteRecords[routeIndex].selectedTruck = element.target.value

        const recordFound = selectedRouteRecords.find(record => record._id == currRouteRecord._id)
        if(recordFound){
            const newRecord = {
                ...recordFound,
                selectedTruck: element.target.value
            }

            let newSelectedRouteRecords = selectedRouteRecords.filter(record => record._id != currRouteRecord._id)
            newSelectedRouteRecords.push(newRecord)
            setSelectedRouteRecords(newSelectedRouteRecords)
        }

        setRouteRecords(newRouteRecords)
    }

    const handleRouteCompleteSelect = async (routeIndex) => {
        const currRouteRecord = {
            ...routeRecords[routeIndex]
        }
        const recordFound = selectedRouteRecords.find(record => record._id == currRouteRecord._id)

        if(recordFound){
            const newSelectedRouteRecords = selectedRouteRecords.filter(record => record._id != recordFound._id)
            setSelectedRouteRecords(newSelectedRouteRecords)
        } else{
            const newSelectedRouteRecords = [...selectedRouteRecords, currRouteRecord]
            setSelectedRouteRecords(newSelectedRouteRecords)
        }
    }

    const handleOrderSelect = async (routeIndex, orderIndex) => {

        const currRouteRecordId = routeRecords[routeIndex]._id
        const currOrderId = routeRecords[routeIndex].orders[orderIndex]
        
        if(currRouteRecordId && currOrderId){
            let newOrdersForceQueue = []
            let newRouteRecordOrdersObject = {}
            const routeRecordObjectFound = selectedRouteRecordOrders.find(currElement => currElement.routeRecordId == currRouteRecordId)

            if(routeRecordObjectFound){
                const orderIdFound = routeRecordObjectFound.orders.find(currOrder => currOrder == currOrderId)

                if(orderIdFound){
                    // Remove order id from orders to force queue for route record
                    newOrdersForceQueue = routeRecordObjectFound.orders.filter(order => order != currOrderId)

                } else{
                    // Add order id to orders to force queue for route record
                    newOrdersForceQueue = [...routeRecordObjectFound.orders, currOrderId]

                }

                if(newOrdersForceQueue.length > 0){
                    newRouteRecordOrdersObject = {
                        routeRecordId: currRouteRecordId,
                        orders: newOrdersForceQueue
                    }
    
                    const newSelectedRouteRecordOrders = selectedRouteRecordOrders.filter(curr => curr.routeRecordId != currRouteRecordId)
                    setSelectedRouteRecordOrders([...newSelectedRouteRecordOrders, newRouteRecordOrdersObject])

                } else{
                    const newSelectedRouteRecordOrders = selectedRouteRecordOrders.filter(curr => curr.routeRecordId != currRouteRecordId)
                    setSelectedRouteRecordOrders([...newSelectedRouteRecordOrders])
                    
                }
                
            } else{
                // Add route record id and order id to orders to force queue
                newRouteRecordOrdersObject = {
                    routeRecordId: currRouteRecordId,
                    orders: [currOrderId]
                }

                setSelectedRouteRecordOrders([...selectedRouteRecordOrders, newRouteRecordOrdersObject])
            }


        } else{
            console.log("Wheres the Food!!?")
        }
    }
    
    const handleCreateSpecialRoute = async () => {
        setIsActionsDisabled(true)
        console.log("Create Special Route Here!")

        await postSpecialRouteRecord(accessToken).then(res => {
            // console.log("Response: ", res)
            // console.log("Route Records: ", routeRecords)
            const newSpecialRoute = {
                ...res.data,
                totalCases: 0,
                selectedDriver: '',
                selectedTruck: '',
                routeRecordOrders: []
            }
            setRouteRecords(oldRouteRecords => [...oldRouteRecords, newSpecialRoute])
            setIsActionsDisabled(false)
        }).catch(err => {
            console.log("Error: ", err)
        })
        
        // const isSpecialRouteActive = routeRecords.findIndex(record => record.route[0]._id === supplierInfo.specialRouteId) >= 0
        // if(isSpecialRouteActive){
        //     handleInfoAlert("Only 1 Special Route may be active at any moment.")
        // } else{
        //     await postSpecialRouteRecord(accessToken).then(res => {
        //         // console.log("Response: ", res)
        //         // console.log("Route Records: ", routeRecords)
        //         const newSpecialRoute = {
        //             ...res.data,
        //             totalCases: 0,
        //             selectedDriver: '',
        //             selectedTruck: '',
        //             routeRecordOrders: []
        //         }
        //         setRouteRecords(oldRouteRecords => [...oldRouteRecords, newSpecialRoute])
        //         setIsActionsDisabled(false)
        //     }).catch(err => {
        //         console.log("Error: ", err)
        //     })
        // }
    }

    const handleSpecialRouteDelete = async (index) => {
        
        const currRouteRecord = routeRecords[index]
        
        if(currRouteRecord.routeRecordOrders.length > 0){
            handleInfoAlert("CAUTION: The Special Route cannot be deleted until all clients have been transferred to active routes for the day.")
        } else {
            
            await patchDisableSpecialRouteRecord(accessToken, {routeRecordId: currRouteRecord._id}).then(async res => {
                const newRouteRecords = routeRecords.filter(record => record._id != currRouteRecord._id)
    
                await patchRouteRecords({routeRecords: newRouteRecords}, accessToken).then(res => {
                    setRouteRecords(newRouteRecords)
                }).catch(err => {
                    console.log("Error: ", err)
                })
                
            }).catch(err => {
                console.log("Error: ", err)
            })
        }
        
    }

    const handleRouteRecordDelete = async (index) => {
        
        const currRouteRecord = routeRecords[index]
        
        if(currRouteRecord.routeRecordOrders.length > 0){
            handleInfoAlert("CAUTION: Route Record cannot be deleted until all clients have been transferred to active routes for the day.")
        } else {
            
            await deleteRouteRecord(accessToken, currRouteRecord._id).then(async res => {
                const newRouteRecords = routeRecords.filter(record => record._id != currRouteRecord._id)
    
                await patchRouteRecords({routeRecords: newRouteRecords}, accessToken).then(res => {
                    setRouteRecords(newRouteRecords)
                }).catch(err => {
                    console.log("Error: ", err)
                })
                
            }).catch(err => {
                console.log("Error: ", err)
            })
        }
        
    }

    const handleRouteRecordsReturnToQueue = async () => {
        setIsActionsDisabled(true)

        let selectedRouteRecordOrdersToReturn = selectedRouteRecords.map(record => {
            return {
                routeRecordId: record._id,
                orders: [...record.orders]
            }
        })

        selectedRouteRecordOrders.forEach(record => {
            const foundRouteRecord = selectedRouteRecordOrdersToReturn.find(curr => curr.routeRecordId == record.routeRecordId)

            if(!foundRouteRecord || foundRouteRecord === undefined){
                selectedRouteRecordOrdersToReturn.push({
                    ...record
                })
            }
        })

        await patchRouteRecordForceQueue({ ordersToForceQueueObjectList : selectedRouteRecordOrdersToReturn }, accessToken).then(res => {
            setIsRouting(false)
            setIsActionsDisabled(false)
        }).catch(err => {
            console.log("Error: ", err)
        })
    }

    const handleRouteRecordsSave = async () => {
        setIsActionsDisabled(true)
        await patchRouteRecords({routeRecords: routeRecords}, accessToken).then(res => {
            setIsRouting(false)
            setIsActionsDisabled(false)
        }).catch(err => {
            console.log("Error: ", err)
        })
    }

    const handleRouteRecordsSubmit = async () => {

        setIsActionsDisabled(true)
        let isSubmitValid = true
        let isTotalCasesGTTruckCapacity = false
        let invalidSubmitMessage = ""
        let validatedDrivers = []
        let validatedTrucks = []

        if(selectedRouteRecords.length > 0){
            selectedRouteRecords.forEach(record => {
                if(record.selectedDriver && record.selectedTruck && record.selectedDriver != '' && record.SelectedTruck != ''){
    
                    const driverRepeatedFound = validatedDrivers.find(curr => curr == record.selectedDriver)
                    if(driverRepeatedFound){
                        isSubmitValid = false
                        invalidSubmitMessage += (record.route[0] ? record.route[0].name : 'Special Route') + " has a repeated driver. "
                    } else{
                        validatedDrivers.push(record.selectedDriver)
                    }
    
                    const truckRepeatedFound = validatedTrucks.find(curr => curr == record.selectedTruck)
                    if(truckRepeatedFound){
                        isSubmitValid = false
                        invalidSubmitMessage += (record.route[0] ? record.route[0].name : 'Special Route') + " has a repeated truck. "
                    } else{
                        validatedTrucks.push(record.selectedTruck)
                    }
    
                } else {
    
                    isSubmitValid = false
                    invalidSubmitMessage += (record.route[0] ? record.route[0].name : 'Special Route') + " must have a driver and a truck selected. "
                }

                if(record.selectedTruck && supplierInfo.supplierTrucks.find(curr => curr._id == record.selectedTruck).avgCases < record.totalCases){
                    invalidSubmitMessage += (record.route[0] ? record.route[0].name : 'Special Route') + " has Total cases greater than truck capacity. "
                    isTotalCasesGTTruckCapacity = true
                }
            })
        } else{
            isSubmitValid = false
            invalidSubmitMessage += "No routes selected. Must select at least one route to submit."
        }

        if(isSubmitValid && !isTotalCasesGTTruckCapacity){

            await submitRouteRecords({routeRecords: selectedRouteRecords}, accessToken).then(async res => {
                let newRouteRecords = [...routeRecords]
                selectedRouteRecords.forEach(record => {
                    newRouteRecords = newRouteRecords.filter(curr => curr._id != record._id)
                })

                await patchRouteRecords({routeRecords: newRouteRecords}, accessToken).then(res => {
                    setIsValidSubmit(true)
                    setDialogMessage("Routes were submitted successfully!")
                    setOpen(true)
                    setIsActionsDisabled(false)
                }).catch(err => {
                    console.log("Error: ", err)
                })

                
            }).catch(err => {
                console.log("Error: ", err)
                setDialogMessage("An error occured submitting routes! Contact our team for more details.")
                setOpen(true)
            })
        } else{
            setIsTotalCasesGRTTruckCapacity(isTotalCasesGTTruckCapacity)
            setIsValidSubmit(isSubmitValid)
            setDialogMessage(invalidSubmitMessage)
            setOpen(true)
            setIsActionsDisabled(false)
        }

        
    }

    const handleForceSubmit = async () => {

        setIsActionsDisabled(true)

        await submitRouteRecords({routeRecords: selectedRouteRecords}, accessToken).then(async res => {
            let newRouteRecords = [...routeRecords]
            selectedRouteRecords.forEach(record => {
                newRouteRecords = newRouteRecords.filter(curr => curr._id != record._id)
            })

            await patchRouteRecords({routeRecords: newRouteRecords}, accessToken).then(res => {
                setIsValidSubmit(true)
                setIsTotalCasesGRTTruckCapacity(false)
                setDialogMessage("Routes were submitted successfully!")
                setOpen(true)
                setIsActionsDisabled(false)
            }).catch(err => {
                console.log("Error: ", err)
            })

        }).catch(err => {
            console.log("Error: ", err)
        })
    }

    const handleRouteCasesReviewClick = async (routeIndex) => {
        // console.log("Brrrrr!")
        const currRouteRecord = routeRecords[routeIndex]
        // console.log("Curr rroute record: ", currRouteRecord)
        let recordCasesPerCategoryWithProductsList = []
        let newRouteCasesFavoriteProducts = []
        let totalFavoritesAmount = 0

        let newRouteRecords = [...routeRecords]

        await patchRouteRecords({routeRecords: newRouteRecords}, accessToken).then(res => {
            
        }).catch(err => {
            console.log("Error: ", err)
        })
        
        currRouteRecord.routeRecordOrders.forEach(currOrder => {
            // console.log("Curr order: ", currOrder)
            currOrder.orderCategories.forEach(currCategory => {
                // console.log("Curr category: ", currCategory)
                const itemFoundIndex = recordCasesPerCategoryWithProductsList.findIndex(curr => curr.categoryId == currCategory.categoryId)
                // console.log("Found item index: ", itemFoundIndex)
                if(itemFoundIndex >= 0){
                    const currItemFound = recordCasesPerCategoryWithProductsList[itemFoundIndex]
                    recordCasesPerCategoryWithProductsList[itemFoundIndex] = {
                        ...currItemFound,
                        amount: currItemFound.amount + currCategory.amount
                    }
                } else{
                    recordCasesPerCategoryWithProductsList.push({
                        ...currCategory,
                        products: []
                    })
                }
            })

            currOrder.products.forEach(currProduct => {
                const itemFoundIndex = recordCasesPerCategoryWithProductsList.findIndex(curr => curr.categoryId == currProduct.categoryId)
                if(itemFoundIndex >= 0){
                    const foundProductIndex = recordCasesPerCategoryWithProductsList[itemFoundIndex].products.findIndex((curr) => curr._id == currProduct._id)
                    const currFoundProduct = recordCasesPerCategoryWithProductsList[itemFoundIndex].products[foundProductIndex]

                    if(foundProductIndex >= 0){
                        // recordCasesPerCategoryWithProductsList[itemFoundIndex].products[foundProductIndex] = 

                        const totalAmount = currProduct.amount + currFoundProduct.totalAmount
                        const newCustomerTotalsList = [...currFoundProduct.customerTotalsList, {
                            amount: currProduct.amount,
                            companyName: currOrder.company[0]?.businessName,
                            locationName: currOrder.location[0]?.locationAddress2 ? currOrder.location[0]?.locationAddress1 + ", " + currOrder.location[0]?.locationAddress2 : currOrder.location[0]?.locationAddress1,
                            orderId: currOrder._id,
                        }]

                        recordCasesPerCategoryWithProductsList[itemFoundIndex].products[foundProductIndex] = {
                            ...currProduct, 
                            customerTotalsList: newCustomerTotalsList,
                            totalAmount: totalAmount
                        }

                    } else {
                        const customerTotalsList = [{
                            amount: currProduct.amount,
                            companyName: currOrder.company[0]?.businessName,
                            locationName: currOrder.location[0]?.locationAddress2 ? currOrder.location[0]?.locationAddress1 + ", " + currOrder.location[0]?.locationAddress2 : currOrder.location[0]?.locationAddress1,
                            orderId: currOrder._id,
                        }]
                        const totalAmount = currProduct.amount
                        recordCasesPerCategoryWithProductsList[itemFoundIndex].products.push({ 
                            ...currProduct, 
                            customerTotalsList: customerTotalsList,
                            totalAmount: totalAmount
                        })

                    }
                    
                } else{
                    console.log("Nothing to serious, but this should not be happening.")
                }

                // TODO: Create favorites review
                if(currProduct.isFavorite){
                    totalFavoritesAmount += currProduct.amount
                    const foundItemIndex = newRouteCasesFavoriteProducts.findIndex(curr => curr._id == currProduct._id)
                    if(foundItemIndex >= 0){
                        
                        const currFoundProduct = newRouteCasesFavoriteProducts[foundItemIndex]

                        const totalAmount = currProduct.amount + currFoundProduct.totalAmount
                        const newCustomerTotalsList = [...currFoundProduct.customerTotalsList, {
                            amount: currProduct.amount,
                            companyName: currOrder.company[0]?.businessName,
                            locationName: currOrder.location[0]?.locationAddress2 ? currOrder.location[0]?.locationAddress1 + ", " + currOrder.location[0]?.locationAddress2 : currOrder.location[0]?.locationAddress1,
                            orderId: currOrder._id,
                        }]
                        newRouteCasesFavoriteProducts[foundItemIndex] = {
                            ...currProduct, 
                            customerTotalsList: newCustomerTotalsList,
                            totalAmount: totalAmount
                        }
                    } else{
                        const customerTotalsList = [{
                            amount: currProduct.amount,
                            companyName: currOrder.company[0]?.businessName,
                            locationName: currOrder.location[0]?.locationAddress2 ? currOrder.location[0]?.locationAddress1 + ", " + currOrder.location[0]?.locationAddress2 : currOrder.location[0]?.locationAddress1,
                            orderId: currOrder._id,
                        }]
                        const totalAmount = currProduct.amount
                        newRouteCasesFavoriteProducts.push({
                            ...currProduct,
                            customerTotalsList: customerTotalsList,
                            totalAmount: totalAmount
                        })
                    }
                    
                }

            })
        })

        // console.log("~~~Result before products: ", recordCasesPerCategoryWithProductsList)

        setSelectedReviewRouteIndex(routeIndex)
        setSelectedCasesRoute(currRouteRecord.route[0].name)
        setRouteCasesPerCategoryWithProducts(recordCasesPerCategoryWithProductsList)
        setRouteCasesFavoriteProducts(newRouteCasesFavoriteProducts)
        setIsRouteCasesDetail(true)

    }

    const handleRouteCasesReviewUpdate = (newRouteRecords) => {
        // console.log("Brrrrr!")
        const currRouteRecord = newRouteRecords[selectedReviewRouteIndex]
        // console.log("Curr rroute record: ", currRouteRecord)
        let recordCasesPerCategoryWithProductsList = []
        let newRouteCasesFavoriteProducts = []
        let totalFavoritesAmount = 0
        
        currRouteRecord.routeRecordOrders.forEach(currOrder => {
            // console.log("Curr order: ", currOrder)
            currOrder.orderCategories.forEach(currCategory => {
                // console.log("Curr category: ", currCategory)
                const itemFoundIndex = recordCasesPerCategoryWithProductsList.findIndex(curr => curr.categoryId == currCategory.categoryId)
                // console.log("Found item index: ", itemFoundIndex)
                if(itemFoundIndex >= 0){
                    const currItemFound = recordCasesPerCategoryWithProductsList[itemFoundIndex]
                    recordCasesPerCategoryWithProductsList[itemFoundIndex] = {
                        ...currItemFound,
                        amount: currItemFound.amount + currCategory.amount
                    }
                } else{
                    recordCasesPerCategoryWithProductsList.push({
                        ...currCategory,
                        products: []
                    })
                }
            })

            currOrder.products.forEach(currProduct => {
                const itemFoundIndex = recordCasesPerCategoryWithProductsList.findIndex(curr => curr.categoryId == currProduct.categoryId)
                if(itemFoundIndex >= 0){
                    const foundProductIndex = recordCasesPerCategoryWithProductsList[itemFoundIndex].products.findIndex((curr) => curr._id == currProduct._id)
                    const currFoundProduct = recordCasesPerCategoryWithProductsList[itemFoundIndex].products[foundProductIndex]

                    if(foundProductIndex >= 0){
                        // recordCasesPerCategoryWithProductsList[itemFoundIndex].products[foundProductIndex] = 

                        const totalAmount = currProduct.amount + currFoundProduct.totalAmount
                        const newCustomerTotalsList = [...currFoundProduct.customerTotalsList, {
                            amount: currProduct.amount,
                            companyName: currOrder.company[0]?.businessName,
                            locationName: currOrder.location[0]?.locationAddress2 ? currOrder.location[0]?.locationAddress1 + ", " + currOrder.location[0]?.locationAddress2 : currOrder.location[0]?.locationAddress1,
                            orderId: currOrder._id,
                        }]

                        recordCasesPerCategoryWithProductsList[itemFoundIndex].products[foundProductIndex] = {
                            ...currProduct, 
                            customerTotalsList: newCustomerTotalsList,
                            totalAmount: totalAmount
                        }

                    } else {
                        const customerTotalsList = [{
                            amount: currProduct.amount,
                            companyName: currOrder.company[0]?.businessName,
                            locationName: currOrder.location[0]?.locationAddress2 ? currOrder.location[0]?.locationAddress1 + ", " + currOrder.location[0]?.locationAddress2 : currOrder.location[0]?.locationAddress1,
                            orderId: currOrder._id,
                        }]
                        const totalAmount = currProduct.amount
                        recordCasesPerCategoryWithProductsList[itemFoundIndex].products.push({ 
                            ...currProduct, 
                            customerTotalsList: customerTotalsList,
                            totalAmount: totalAmount
                        })

                    }
                    
                } else{
                    console.log("Nothing to serious, but this should not be happening.")
                }

                if(currProduct.isFavorite){
                    totalFavoritesAmount += currProduct.amount
                    const foundItemIndex = newRouteCasesFavoriteProducts.findIndex(curr => curr._id == currProduct._id)
                    if(foundItemIndex >= 0){
                        
                        const currFoundProduct = newRouteCasesFavoriteProducts[foundItemIndex]

                        const totalAmount = currProduct.amount + currFoundProduct.totalAmount
                        const newCustomerTotalsList = [...currFoundProduct.customerTotalsList, {
                            amount: currProduct.amount,
                            companyName: currOrder.company[0]?.businessName,
                            locationName: currOrder.location[0]?.locationAddress2 ? currOrder.location[0]?.locationAddress1 + ", " + currOrder.location[0]?.locationAddress2 : currOrder.location[0]?.locationAddress1,
                            orderId: currOrder._id,
                        }]
                        newRouteCasesFavoriteProducts[foundItemIndex] = {
                            ...currProduct, 
                            customerTotalsList: newCustomerTotalsList,
                            totalAmount: totalAmount
                        }
                    } else{
                        const customerTotalsList = [{
                            amount: currProduct.amount,
                            companyName: currOrder.company[0]?.businessName,
                            locationName: currOrder.location[0]?.locationAddress2 ? currOrder.location[0]?.locationAddress1 + ", " + currOrder.location[0]?.locationAddress2 : currOrder.location[0]?.locationAddress1,
                            orderId: currOrder._id,
                        }]
                        const totalAmount = currProduct.amount
                        newRouteCasesFavoriteProducts.push({
                            ...currProduct,
                            customerTotalsList: customerTotalsList,
                            totalAmount: totalAmount
                        })
                    }
                    
                }
            })
        })

        setSelectedCasesRoute(currRouteRecord.route[0].name)
        setRouteCasesPerCategoryWithProducts(recordCasesPerCategoryWithProductsList)
        setRouteCasesFavoriteProducts(newRouteCasesFavoriteProducts)
        setIsRouteCasesDetail(true)

    }

    const handleSpecialRouteNameChange = (customName, recordId) => {
        const currRouteRecordIndex = routeRecords.findIndex(record => record._id == recordId)
        const currSelectedRouteRecordIndex = selectedRouteRecords.findIndex(record => record._id == recordId)

        if(currRouteRecordIndex >= 0){
            let newRouteRecords = [...routeRecords]
            let newRouteRecord = {
                ...routeRecords[currRouteRecordIndex],
                customName: customName
            }

            newRouteRecords[currRouteRecordIndex] = newRouteRecord
            setRouteRecords(newRouteRecords)
        }

        if(currSelectedRouteRecordIndex >= 0){
            let newSelectedRouteRecords = [...selectedRouteRecords]
            let newSelectedRouteRecord = {
                ...selectedRouteRecords[currRouteRecordIndex],
                customName: customName
            }

            newSelectedRouteRecords[currSelectedRouteRecordIndex] = newSelectedRouteRecord
            setSelectedRouteRecords(newSelectedRouteRecords)
        }
    }

    return (
        <>
            { !isOrderEdit ? 
                !isRouteCasesDetail ?
                    <Card>
                        <CardHeader title={"Routing"}>
                            <CardHeaderToolbar>
                                <button type="button" className="btn btn-primary mr-5" onClick={() => setIsRouting(false)} >Back</button>
                                { isRouteRecordsFetchFinished ?
                                        <>
                                            <button type="button" disabled={isActionsDisabled} className="btn btn-primary mr-5" onClick={() => handleCreateSpecialRoute(false)} >Create Special Route {
                                                isActionsDisabled ?
                                                    <span className="mr-3 ml-3 spinner spinner-success"></span>
                                                :
                                                    null
                                            }</button>
                                            <button type="button" disabled={isActionsDisabled} className="btn btn-primary mr-5" onClick={() => handleRouteRecordsReturnToQueue()} >Return to Queue{
                                                isActionsDisabled ?
                                                    <span className="mr-3 ml-3 spinner spinner-success"></span>
                                                :
                                                    null
                                            }</button>
                                            <button type="button" disabled={isActionsDisabled} className="btn btn-primary mr-5" onClick={() => handleRouteRecordsSave()} >Save{
                                                isActionsDisabled ?
                                                    <span className="mr-3 ml-3 spinner spinner-success"></span>
                                                :
                                                    null
                                            }</button>
                                            <button type="button" disabled={isActionsDisabled} className="btn btn-primary mr-5" onClick={() => handleRouteRecordsSubmit()} >Submit{
                                                isActionsDisabled ?
                                                    <span className="mr-3 ml-3 spinner spinner-success"></span>
                                                :
                                                    null
                                            }</button>
                                        </>
                                    :
                                        null
                                }
                                {
                                    isCutOffTimeActive ? 
                                        <span className="label label-lg label-light-danger label-inline">
                                            Cut-Off time still open!
                                        </span>
                                        :
                                        null
                                }
                                
                            </CardHeaderToolbar>
                        </CardHeader>
                        <CardBody>
                            {
                                isRouteRecordsFetchFinished ? 
                                    <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
                                        <div className="row">
                                            {
                                                routeRecords.map((record, index) => {
                                                    return (
                                                        <div key={record._id + 'p'} className="col-md-6 cursor-pointer">
                                                            <Card className={`d-flex align-items-center mb-9 rounded p-5 h-100`}  >
                                                                <div className="row">
                                                                    <h4>{record.route[0] ? record.route[0].name == 'SPECIAL ROUTE' ? 'S.R.' : record.route[0].name : "SPECIAL ROUTE"}</h4>
                                                                        
                                                                    {
                                                                        record.route[0]._id === supplierInfo.specialRouteId ?
                                                                            <div className="ml-3">
                                                                                <TextField
                                                                                    size="small"
                                                                                    // Autofocus on textfield
                                                                                    autoFocus
                                                                                    placeholder="Special Route Name"
                                                                                    fullWidth
                                                                                    value={record.customName ? record.customName : ""}
                                                                                    onChange={(e) => handleSpecialRouteNameChange(e.target.value, record._id)}
                                                                                    onKeyDown={(e) => {
                                                                                            if (e.key !== "Escape") {
                                                                                            // Prevents autoselecting item while typing (default Select behaviour)
                                                                                            e.stopPropagation();
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        :
                                                                            null
                                                                    }
                                                                </div>
                                                                <div className="row mt-5">
                                                                    <button type="button" className="btn btn-primary" onClick={() => handleRouteCasesReviewClick(index)} >Cases Review</button>
                                                                    {
                                                                        record.route[0]._id === supplierInfo.specialRouteId ?
                                                                            <button type="button" className="btn btn-primary ml-5" onClick={() => handleSpecialRouteDelete(index)} >Delete</button>
                                                                        :
                                                                            record.routeRecordOrders.length == 0 ?
                                                                                <button type="button" className="btn btn-primary ml-5" onClick={() => handleRouteRecordDelete(index)} >Delete</button>
                                                                            :
                                                                                null
                                                                    }
                                                                </div>
                                                                    
                                                                <CardBody className="w-100">
                                                                    <table className="table table-head-custom table-head-bg table-vertical-center table-padding w-100">
                                                                        <thead>
                                                                            <tr>
                                                                                <th className="text-center" >Select</th>
                                                                                <th className="text-center" >Sequence</th>
                                                                                <th className="text-center" >Client</th>
                                                                                <th className="text-center" >Cases</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <Droppable droppableId={record._id} key={record._id + 'k'} type="task">
                                                                                    {(provided, snapshot) => (
                                                                            <tbody
                                                                                ref={provided.innerRef}
                                                                                {...provided.droppableProps}
                                                                            >
                                                                                {
                                                                                    record.routeRecordOrders.map((order, orderIndex) => {
                                                                                        return (
                                                                                            <Draggable draggableId={order._id} key={order._id + 'd'} index={orderIndex}>
                                                                                                {(provided, snapshot) => (
                                                                                                    <tr 
                                                                                                        {...provided.draggableProps}
                                                                                                        {...provided.dragHandleProps}
                                                                                                        ref={provided.innerRef}
                                                                                                    >
                                                                                                        <td>
                                                                                                            <label className="checkbox text-center checkbox-lg checkbox-single flex-shrink-0 m-0 mx-4">
                                                                                                                <input type="checkbox" name="" onClick={() => handleOrderSelect(index, orderIndex)} />
                                                                                                                <span></span>
                                                                                                            </label>
                                                                                                        </td>
                                                                                                        <td onClick={() => handleOrderClick(order, record._id)}>
                                                                                                            <span className="d-block text-dark text-center font-weight-bolder text-hover-primary font-size-lg">
                                                                                                                {orderIndex + 1}
                                                                                                            </span>
                                                                                                        </td>
                                                                                                        <td onClick={() => handleOrderClick(order, record._id)}>
                                                                                                            <span className="d-block text-dark text-center font-weight-bolder text-hover-primary font-size-lg">
                                                                                                                {order.company[0].businessName}
                                                                                                            </span>
                                                                                                        </td>
                                                                                                        <td onClick={() => handleOrderClick(order, record._id)}>
                                                                                                            {
                                                                                                                order.orderCategories.map(cat => {
                                                                                                                    return (
                                                                                                                        <span className="d-block text-dark text-center font-weight-bolder text-hover-primary font-size-lg">
                                                                                                                            {cat.name + ": " + cat.amount}
                                                                                                                        </span>
                                                                                                                    )
                                                                                                                })
                                                                                                            }
                                                                                                            
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                )}
                                                                                            </Draggable>
                                                                                        )
                                                                                    })
                                                                                }
                                                                                {provided.placeholder}
                                                                            </tbody>
                                                                            )}
                                                                        </Droppable>
                                                                    </table>
                                                                </CardBody>
                                                                <CardFooter style={{ width: "100%" }}>
                                                                    <div className="row">
                                                                        <div className="col-9">
                                                                            <FormControl variant="filled" style={{ width: "100%" }}>
                                                                                <InputLabel>Driver</InputLabel>
                                                                                <Select
                                                                                    MenuProps={{ autoFocus: false }}
                                                                                    value={record.selectedDriver}
                                                                                    input={<FilledInput name="selectedDriver" id="selectedDriver"
                                                                                        onChange={(element) => {
                                                                                            handleDriverChange(element, index);
                                                                                        } }
                                                                                        type="text"
                                                                                        className="h-auto mb-5" />}>

                                                                                    {supplierInfo.supplierDrivers.map((driver,index) =>(
                                                                                        <MenuItem key={driver._id} value={driver._id}>{driver.name}</MenuItem>
                                                                                    ))

                                                                                    }

                                                                                </Select>
                                                                            </FormControl>
                                                                        </div>
                                                                        <div className="col-3">
                                                                            {
                                                                                record.selectedTruck ? 
                                                                                    <div className="row" >
                                                                                        Truck Capacity: {supplierInfo.supplierTrucks.find(curr => curr._id == record.selectedTruck).avgCases}
                                                                                    </div>
                                                                                :
                                                                                null
                                                                            }
                                                                            
                                                                            <div className="row" >
                                                                                {
                                                                                    record.selectedTruck && supplierInfo.supplierTrucks.find(curr => curr._id == record.selectedTruck).avgCases < record.totalCases ?
                                                                                        <span className="label label-lg label-light-danger label-inline">
                                                                                            Total: {record.totalCases}
                                                                                        </span>
                                                                                        :
                                                                                        "Total: " + record.totalCases
                                                                                }
                                                                                
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    <div className="row">
                                                                        <div className="col-9">
                                                                            <FormControl variant="filled" style={{ width: "100%" }}>
                                                                                <InputLabel>Truck</InputLabel>
                                                                                <Select
                                                                                    MenuProps={{ autoFocus: false }}
                                                                                    value={record.selectedTruck}
                                                                                    input={<FilledInput name="selectedTruck" id="selectedTruck"
                                                                                        onChange={(element) => {
                                                                                            handleTruckChange(element, index);
                                                                                        } }
                                                                                        type="text"
                                                                                        className="h-auto mb-5" />}>

                                                                                    {supplierInfo.supplierTrucks.map((truck,index) =>(
                                                                                        <MenuItem key={truck._id} value={truck._id}>{truck.name + " (CAP " + truck.avgCases + ")"}</MenuItem>
                                                                                    ))

                                                                                    }
                                                                                </Select>
                                                                            </FormControl>
                                                                        </div>
                                                                        <div className="col-3">
                                                                            <label className="checkbox checkbox-lg checkbox-single flex-shrink-0 m-0 mx-4">

                                                                                <input type="checkbox" name="" onClick={() => { handleRouteCompleteSelect(index) }} />
                                                                                <span></span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </CardFooter>
                                                            </Card>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </DragDropContext>
                                :
                                    <div className="row justify-content-center mt-20">
                                        <span className="ml-3 spinner spinner-lg spinner-primary"></span>
                                    </div>
                            }
                            
                        </CardBody>
                    </Card>
                    :
                    <RouteCasesDetail routeCasesPerCategoryWithProducts={routeCasesPerCategoryWithProducts} setRouteCasesPerCategoryWithProducts={setRouteCasesPerCategoryWithProducts} routeCasesFavoriteProducts={routeCasesFavoriteProducts} 
                        setRouteCasesFavoriteProducts={setRouteCasesFavoriteProducts} setIsRouteCasesDetail={setIsRouteCasesDetail} 
                        isRouteCasesDetail={isRouteCasesDetail} setSelectedRouteRecords={setSelectedRouteRecords} selectedCasesRoute={selectedCasesRoute} accessToken={accessToken} 
                        setSelectedReviewRouteIndex={setSelectedReviewRouteIndex} setSelectedCasesRoute={setSelectedCasesRoute} setIsCasesReviewUpdated={setIsCasesReviewUpdated} isCasesReviewUpdated={isCasesReviewUpdated} />
                :
                <OrderDetails order={order} setOrder={setOrder} setIsOrderDetails={setIsOrderEdit} isOrderDetails={isOrderEdit} setSelectedRecordId={setSelectedRecordId} 
                    selectedRecordId={selectedRecordId} setIsOrderUpdated={setIsOrderUpdated} isOrderUpdated={isOrderUpdated} />
            }

            <Dialog
                open={open}
                onClose={() => handleClose()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Order Confirmation"}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {dialogMessage}
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                {
                    !isTotalCasesGRTTruckCapacity ? 
                        isValidSubmit ? 
                            <Button onClick={() => handleClose(true)} color="primary" autoFocus>
                                OK
                            </Button>
                            :
                            <Button onClick={() => handleClose()} color="primary" autoFocus>
                                OK
                            </Button>
                    :
                        isValidSubmit ?
                            <div>
                                <Button onClick={() => handleClose()} color="primary" autoFocus>
                                    Cancel
                                </Button>
                                <Button onClick={() => handleForceSubmit()} color="primary" autoFocus>
                                    Submit
                                </Button>
                            </div>
                            :
                            <Button onClick={() => handleClose()} color="primary" autoFocus>
                                OK
                            </Button>
                }
                
                </DialogActions>
            </Dialog>
            <Dialog
                open={openInfo}
                onClose={() => handleInfoClose()}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Order Confirmation"}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {dialogInfoMessage}
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleInfoClose()} color="primary" autoFocus>
                        OK
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    );
}

export default injectIntl(RoutingPage);