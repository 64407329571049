import {Card, CardBody} from "../../../../../_metronic/_partials/controls";
import React, { useState, useEffect } from "react";
import { ProductModal } from "./ProductModal";
import {postProducts, postProductsToOrder} from "../ordersCrud";
import {useDispatch, useSelector} from "react-redux";
import {cartCountActions} from "../../../../../redux/reducers/cartCount";
import {
    Collapse
  } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

export function ProductBox({product,supplier,company,locations,setShowSnackBar, 
                        getSupplierProductsNameBrand,  setProducts, setPaginationOptions, 
                        searchBrand, searchCategory, paginationOptions,
                        isOrderSummary, setIsCartUpdated, isCartUpdated, orderId, currentOrders, 
                        setCurrentOrders, defaultInvoiceDate, defaultShipDate, order }){

    const [showProductModal, setShowProductModal] = useState(false);
    const [expanded2, setExpanded2] = useState(false);
    const [locationsToOrder,setLocationsToOrder] = useState([])
    const [locationList,setLocationList] = useState()
    const [open, setOpen] = React.useState(false);
    const [isLocationsSet, setIsLocationsSet] = useState(false);
    const [dialogMessage, setDialogMessage] = useState("");
    const dispatch = useDispatch();

    const {user,accessToken} = useSelector(
        ({auth}) => ({
            user: auth.user != null ? auth.user : null,
            accessToken: auth.authToken,
        })
    )

    useEffect(() => {
        let currentLocations = []
        locations.forEach((location,index) => {
            let locationObject = {
                _id: location._id,
                locationName: location.locationName,
                locationAddress: location.locationAddress2 ? location.locationAddress1 + " " + location.locationAddress2 : location.locationAddress1,
                locationCity: location.locationCity,
                amount: ""
            }
            currentLocations.push(locationObject)
        })

        setLocationList(currentLocations)
        setLocationsToOrder([])
        setIsLocationsSet(true)
    }, [ locations ])

    function handleExpandClick2() {
        setExpanded2(!expanded2);
    }

    function handleClose() {
        setOpen(false);
        setDialogMessage("")
        // if(isOrdersValid){
        //     dispatch(cartCountActions.getProductCount(accessToken, user.id))
        //     props.history.push('/orders');
        // }
        
    }

    function handleOpenDialogue(message){
        setDialogMessage(message)
        setOpen(true);
    }

    // const openLicensesModal = () => {

    //     setOpenVerificationModal({
    //         permitsModal: false,
    //         licensesModal: true,
    //         creditModal: false,
    //         otherModal: false
    //     })
    // }

    // const handleLocationChange = (e,locationParam,index,amount) => {

    //     let locationToOrder = locationsToOrder.find(location => location._id === locationParam._id)
    //     console.log(locationToOrder,locationParam,index,amount);

    //     if(!locationToOrder){
    //         console.log("Entré aqui")

    //         if(e.target.value){
    //             setLocationsToOrder(locationsToOrder =>[...locationsToOrder,locationParam])

    //         }
    //         console.log(locationParam,locationToOrder)
    //     }else{
    //         if(!e.target.value){
    //             let locationsTemp = [...locationToOrder]
    //             locationsTemp.slice(index,1)
    //             setLocationsToOrder(locationsTemp);
    //             console.log(locationsTemp)
    //         }
    //     }
    //     console.log("Valor",e.target.value)
    // }

    const handleAmountChange = (locationParam,amount) => {
        let locationToOrderIndex = locationsToOrder.findIndex(location => location._id === locationParam._id)
        let locationInListIndex = locationList.findIndex(location => location._id === locationParam._id)
        let locationsTemp = [...locationsToOrder];
        let locationsListTemp = [...locationList]

        if(amount > 0) {
            if(locationToOrderIndex !== -1) {
                locationsTemp[locationToOrderIndex] = {...locationsTemp[locationToOrderIndex],amount:amount}
                locationsListTemp[locationInListIndex] = {...locationsListTemp[locationInListIndex],amount:amount}
            } else {
                locationsTemp.push({ ...locationParam , amount:amount })
                locationsListTemp[locationInListIndex] = {...locationsListTemp[locationInListIndex],amount:amount}
            }
        } else {
            locationsTemp = locationsTemp.filter(location => location._id !== locationParam._id)
            locationsListTemp[locationInListIndex] = {...locationsListTemp[locationInListIndex],amount:amount}
        }

        setLocationsToOrder(locationsTemp)
        setLocationList(locationsListTemp)

        // console.log("Locations: ", locationsToOrder)

        // if(locationToOrderIndex !== undefined && locationToOrderIndex !== null && locationToOrderIndex !== -1){
        //     locationsTemp[locationToOrderIndex] = {...locationsTemp[locationToOrderIndex],amount:amount}
        //     locationsListTemp[locationInListIndex] = {...locationsListTemp[locationInListIndex],amount:amount}

        //     setLocationsToOrder(locationsTemp)
        //     setLocationList(locationsListTemp)

        //     console.log("Llegamos",locationsTemp,locationsToOrder)
        // }
    }

    const addToCart = () => {
        // console.log(locationsToOrder)

        const isFuelFree = company.isFuelFree ? company.isFuelFree : false 
        const shipVia = company.shipVia ? company.shipVia : ""
        
        // let isAddToCartValid = true
        // let amountToOrder = 0

        // locationsToOrder.forEach(curr => {
        //     amountToOrder += curr.amount
        // })

        // if(amountToOrder > product.qtyOnHand){
        //     isAddToCartValid = false
        // }

        // if(isAddToCartValid){
        console.log("Pasando por aqui!")
        if(!isOrderSummary){
            console.log("Pasando por aca!")

            if(locationsToOrder.length > 0 && locationsToOrder[0]?.amount > 0){

                postProducts(user.id, accessToken,locationsToOrder,product,supplier._id,supplier.entityName,company._id,company.businessName, isFuelFree, shipVia ).then(response =>{
                    if(response.status === 200){
                        dispatch(cartCountActions.getProductCount(accessToken, user.id))
                        setShowSnackBar(true)
        
                        
                        let locationListAmountZero = locationList.map(location => {
                            return {
                                ...location,
                                amount : ""
                            }
                        })
        
                        setExpanded2(!expanded2)
        
                        setLocationsToOrder([])
                        setLocationList([...locationListAmountZero])

                        const ItemUpdated = {
                            ...product,
                            amount: locationsToOrder[0].amount,
                        }

                        let itemUpdatedPrice = (ItemUpdated.listPrice || ItemUpdated.listPrice == '') ? ItemUpdated.listPrice : ItemUpdated.price
                        if(ItemUpdated.isCatchWeight){
                            itemUpdatedPrice = itemUpdatedPrice * ItemUpdated.avgWeight
                            ItemUpdated.estimatedPrice = Math.round( parseFloat(itemUpdatedPrice) * 1e2 ) / 1e2
                        }

                        let updatedOrder = {...response.data}

                        // const itemFoundInOrderIndex = updatedOrder.products.findIndex(currItem => currItem._id == ItemUpdated._id)

                        // if(itemFoundInOrderIndex >= 0){
                        //     // Update item amount, total order cost and total order amount
                        //     const newItemAmount = updatedOrder.products[itemFoundInOrderIndex].amount + ItemUpdated.amount
                        //     updatedOrder.products[itemFoundInOrderIndex].amount = newItemAmount
                            
                        // } else{
                        //     updatedOrder.products.push(ItemUpdated)
                        // }

                        // const newTotalCost = updatedOrder.totalCost += (ItemUpdated.amount * itemUpdatedPrice)
                        // const newTotalProductQty = updatedOrder.totalProductQty += ItemUpdated.amount
                        // updatedOrder.totalCost = Math.round( parseFloat(newTotalCost) * 1e2 ) / 1e2
                        // updatedOrder.totalProductQty =newTotalProductQty

                        if(!updatedOrder.comment){
                            updatedOrder.comment = ""
                        }
                        if(!updatedOrder.customerPo){
                            updatedOrder.customerPo = ""
                        }
                        if(!updatedOrder.shipVia){
                            updatedOrder.shipVia = shipVia
                        }
                        if(!updatedOrder.invoiceDate){
                            updatedOrder.invoiceDate = defaultInvoiceDate
                        }
                        if(!updatedOrder.shipDate){
                            updatedOrder.shipDate = defaultShipDate
                        }
                        

                        let newCurrentOrders = [...currentOrders]

                        const currOrderFoundIndex = newCurrentOrders.findIndex(currOrder => currOrder._id == updatedOrder._id)
                        // Only add new product to order dont alter other changes already made. 

                        if(currOrderFoundIndex >= 0){
                            // console.log("Added product: ", product)
                            let currOrder = {...newCurrentOrders[currOrderFoundIndex]}

                            console.log("Updated order: ", updatedOrder)

                            // const productFoundInOrderIndex = currOrder.products.findIndex(item => item._id == product._id)
                            
                            // const newProduct = updatedOrder.products.find(item => item._id == product._id)

                            // console.log("New product: ", newProduct)
                            // if(productFoundInOrderIndex >= 0){
                            //     const oldProduct = currOrder.products[productFoundInOrderIndex]
                            //     console.log("Old product: ", oldProduct)
                                
                            //     const oldTotalCost = currOrder.totalCost
                            //     let newTotalCost = oldTotalCost - oldProduct.amount * itemUpdatedPrice
                            //     newTotalCost += newProduct.amount * itemUpdatedPrice

                            //     const oldTotalAmount = currOrder.totalProductQty
                            //     let newTotalAmount = oldTotalAmount - oldProduct.amount
                            //     newTotalAmount += newProduct.amount
                                
                            //     currOrder.totalProductQty = newTotalAmount
                            //     currOrder.totalCost = Math.round( parseFloat(newTotalCost) * 1e2 ) / 1e2
                            //     currOrder.products[productFoundInOrderIndex].amount = newProduct.amount
                            // } else {
                            //     let newTotalCost = currOrder.totalCost + newProduct.amount * itemUpdatedPrice
                            //     let newTotalAmount = currOrder.totalProductQty + newProduct.amount
                                
                            //     currOrder.totalProductQty = newTotalAmount
                            //     currOrder.totalCost = Math.round( parseFloat(newTotalCost) * 1e2 ) / 1e2
                            //     currOrder.products.push(newProduct)
                            // }

                            console.log("Before Bio-Ware if!")
                            if(process.env.REACT_APP_BIOWARE_TENANT == user.tenant){
            
                                console.log("Inside if!")
                                const fuelItem = updatedOrder.products.find(product => product._id == process.env.REACT_APP_FUEL_ID)
                                if(fuelItem){

                                    let products = updatedOrder.products.filter(product => product._id !== fuelItem._id)
            
                                    products.push(fuelItem)
            
                                    updatedOrder.products = products
                                }
                            }

                            newCurrentOrders[currOrderFoundIndex] = updatedOrder
                        } else{
                            newCurrentOrders.push(updatedOrder)
                        }

                        console.log("New current orders: ", newCurrentOrders)
                        setCurrentOrders(newCurrentOrders)
                    }
                })
            } else{
                handleOpenDialogue("To add to cart amount must be greater than 0.")
            }
        } else{
            let productFound = order.products.find(item => item._id == product._id)
            if(!productFound){
                postProductsToOrder(user.id, accessToken,locationsToOrder,product,supplier._id,supplier.entityName,company._id,company.businessName, isFuelFree, shipVia, orderId ).then(response =>{
                    if(response.status === 200){
                        dispatch(cartCountActions.getProductCount(accessToken, user.id))
                        setShowSnackBar(true)
        
                        
                        let locationListAmountZero = locationList.map(location => {
                            return {
                                ...location,
                                amount : ""
                            }
                        })
        
                        setExpanded2(!expanded2)
        
                        setLocationsToOrder([])
                        setLocationList([...locationListAmountZero])
    
                        setIsCartUpdated(!isCartUpdated)
                    }
                })
            } else{
                handleOpenDialogue("Item already in order. To change item amount edit in item table.")
            }
            
        }

        // getSupplierProductsNameBrand(supplier._id,accessToken,1,paginationOptions.sizePerPage,'', searchBrand, searchCategory, company._id).then(response=>{
        //     setProducts(response.data.products)
        //     setPaginationOptions({
        //         page:1,
        //         totalSize: response.data.totalCount,
        //         sizePerPage: paginationOptions.sizePerPage
        //     })
        // })
        
        // } else{
        //     setDialogMessage("Not enough items to fulfill order, quantity on hand is " + 
        //                         product.qtyOnHand + ". Trying to order " + amountToOrder +
        //                         ".")
        //     setOpen(true);
        // }

        
    }

    const handleAmountInputChange = (locationParam,amount) => {

        let locationToOrderIndex = locationsToOrder.findIndex(location => location._id === locationParam._id)
        let locationInListIndex = locationList.findIndex(location => location._id === locationParam._id)
        let locationsTemp = [...locationsToOrder];
        let locationsListTemp = [...locationList]

        let newAmountParsed = parseInt(amount)

        if(isNaN(newAmountParsed)){
            newAmountParsed = ""
        }

        if(newAmountParsed > 0) {
            if(locationToOrderIndex !== -1) {
                locationsTemp[locationToOrderIndex] = {...locationsTemp[locationToOrderIndex],amount:newAmountParsed}
                locationsListTemp[locationInListIndex] = {...locationsListTemp[locationInListIndex],amount:newAmountParsed}
            } else {
                locationsTemp.push({ ...locationParam , amount:newAmountParsed })
                locationsListTemp[locationInListIndex] = {...locationsListTemp[locationInListIndex],amount:newAmountParsed}
            }
        } else {
            locationsTemp = locationsTemp.filter(location => location._id !== locationParam._id)
            locationsListTemp[locationInListIndex] = {...locationsListTemp[locationInListIndex],amount:newAmountParsed}
        }

        setLocationsToOrder(locationsTemp)
        setLocationList(locationsListTemp)
    }

    // const addToCart = () =>{
    //     setLocationsModal(true)
    //     console.log("Abriendo",product.name)
    // }

    return(
        <>
        <div className="col-md-4 cursor-pointer">
            <Card className={`d-flex align-items-center mb-9 rounded p-5 h-100`} >
                <CardBody>
                    <div className="row justify-content-center">
                        {/* <div className="col-12 text-center p-3" style={{height:"150px",width:"150px"}}>
                            <img onClick={() => setShowProductModal(true)} src={product.imagePath} alt="" style={{height:"120px",width:"120px"}}/>
                        </div> */}
                        <div onClick={() => setShowProductModal(true)} className="col-12 text-center">
                            <h3>{product.description ? product.description : product.name}</h3>
                            {
                                user.tenant == process.env.REACT_APP_BIOWARE_TENANT ? 
                                    <span className="text-dark font-weight-bolder text-hover-primary font-size-lg">
                                        {`Item Id: ${product.sku}`}
                                    </span>
                                :
                                    <span className="text-dark font-weight-bolder text-hover-primary font-size-lg">
                                        {`Sku: ${product.sku}`}
                                    </span>
                            }
                            
                        </div>
                        { product.qtyOnHand || product.qtyOnHand == 0?
                            <div className="col-12 text-center">
                                <span className="text-dark font-weight-bolder text-hover-primary font-size-lg">
                                    {`QoH: ${product.qtyOnHand}`}
                                </span>
                            </div>
                            :
                            null
                        }
                        
                        
                        <div className="col-12 text-center">
                            <span className="text-dark font-weight-bolder text-hover-primary font-size-lg">
                                {`Unit Size: ${product.unitSize} ${product.unitMeasurement}`}
                            </span>
                        </div>
                        <div className="col-12 text-center">
                            <span className="text-dark font-weight-bolder text-hover-primary font-size-lg">
                                {`Units: ${product.units}`}
                            </span>
                        </div>
                        {/* {
                            product.isCatchWeight ? 
                                <div className="col-12 text-center">
                                    <span className="text-dark font-weight-bolder text-hover-primary font-size-lg">
                                        {`Avg Weight: ${product.avgWeight}`}
                                    </span>
                                </div>
                            :
                                null
                        } */}
                        <div className="col-12 text-center">
                            {product.listPrice?
                                    // product.isCatchWeight ? 
                                    //     <>
                                    //         <div className="row justify-content-center px-8 py-md-27 px-md-0">
                                    //             <div className="col-md-10">
                                    //                 <div className="col-sm bg-light-primary px-6 py-4 rounded-xl mr-7">
                                    //                     <div className="col-12 text-center">
                                    //                         <span className="text-dark font-weight-bolder text-hover-primary font-size-lg">
                                    //                             {`Unit Price: $${(product.listPrice).toFixed(2)}`}
                                    //                         </span>
                                    //                     </div>
                                    //                     <div className="col-12 text-center">
                                    //                         <span className="text-dark font-weight-bolder text-hover-primary font-size-lg">
                                    //                             {`Estimated Price: $${(product.avgWeight * product.listPrice).toFixed(2)}`}
                                    //                         </span>
                                    //                     </div>
                                    //                 </div>
                                    //             </div>
                                    //         </div>
                                            
                                    //     </>
                                    // :
                                        <p className="font-size-lg font-weight-bolder"><span className="label label-lg label-light-primary label-inline">${product.listPrice}</span></p>
                                
                                :
                                    // product.isCatchWeight ? 
                                    //     <>
                                    //         <div className="row justify-content-center">
                                    //             <div className="col-md-12">
                                    //                 <div className="col-sm bg-light-primary py-2 rounded-xl">
                                    //                     <div className="col-12 text-center">
                                    //                         <span className="text-dark font-weight-bolder text-hover-primary font-size-lg">
                                    //                             {`Unit Price: $${(product.price).toFixed(2)}`}
                                    //                         </span>
                                    //                     </div>
                                    //                     <div className="col-12 text-center">
                                    //                         <span className="text-dark font-weight-bolder text-hover-primary font-size-lg">
                                    //                             {`Estimated Price: $${(product.avgWeight * product.price).toFixed(2)}`}
                                    //                         </span>
                                    //                     </div>
                                    //                 </div>
                                    //             </div>
                                    //         </div>
                                            
                                    //         </>
                                        
                                    // :
                                        <p className="font-size-lg font-weight-bolder"><span>${product.price}</span></p>
                            }
                        </div>
                    </div>
                    {expanded2 ? null :
                        <div className="col-12 text-center">
                            <button type="button" className="btn btn-primary" onClick={() => handleExpandClick2()}>Add to Cart</button>
                        </div>

                    }
                    
                    <Collapse in={expanded2} timeout="auto" unmountOnExit>
                        <div className="row justify-content-center">
                            <div className="col-12">
                                <div className="table-responsive">
                                    <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                                        {   isLocationsSet ? 
                                                locationList.length > 1 ?
                                                <thead>
                                                    <tr className="text-lect">
                                                        <th className="pr-0" >
                                                            Shipping To
                                                        </th>
                                                        <th style={{ minWidth: "150px" }}></th>
                                                    </tr>
                                                </thead>
                                                :
                                                null
                                            :
                                            null
                                        }
                                        <tbody>
                                        
                                        {   isLocationsSet ? 
                                                locationList.length > 1 ?
                                                    locationList.map((location,index) =>(
                                                        <tr key={location.locationAddress + '--' + index}>
                                                            <td>
                                                                <div className="d-flex align-items-center">
                                                                    <span className="bullet bullet-bar bg-success align-self-stretch"></span>
                                                                    
                                                                    <div className="d-flex flex-column flex-grow-1">
                                                                        <span className="text-dark-75 font-weight-bolder d-block font-size-lg pl-2">
                                                                            {location.locationName}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td style={{minWidth:"125px"}}>
                                                                <div className="col-12 text-right">
                                                                    <div className="mb-1">
                                                                        {/* <button className="btn btn-xs btn-light-success btn-icon mr-2" 
                                                                            disabled={location.amount < 1}
                                                                            onClick={() => {
                                                                                handleAmountChange(location,location.amount - 1)
                                                                            }}>
                                                                            <i className="ki ki-minus icon-xs"></i>
                                                                        </button>
                                                                        <span className="mr-2 font-weight-bolder">{location.amount}</span>
                                                                        <button className="btn btn-xs btn-light-success btn-icon mr-2" 
                                                                            onClick={() => {
                                                                                handleAmountChange(location,location.amount + 1)
                                                                            }} >
                                                                            <i className="ki ki-plus icon-xs"></i>
                                                                        </button> */}
                                                                        <input
                                                                            value={location.amount}
                                                                            onChange={(e) => handleAmountInputChange(location, e.target.value)}
                                                                            className={`form-control form-control-solid h-auto py-2 px-6`}
                                                                            type="number"
                                                                            min="0"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                :
                                                <tr key={locationList[0].locationAddress}>
                                                    <td>
                                                        {/* <div className="text-center ">
                                                            <div className="mb-1">
                                                                <button className="btn btn-xs btn-light-success btn-icon mr-2" 
                                                                    disabled={locationList[0].amount < 1}
                                                                    onClick={() => {
                                                                        handleAmountChange(locationList[0],locationList[0].amount - 1)
                                                                    }}>
                                                                    <i className="ki ki-minus icon-xs"></i>
                                                                </button>
                                                            </div>
                                                        </div> */}
                                                    </td>
                                                    <td style={{minWidth:"125px"}}>
                                                        <div className="text-center ">
                                                            <div className="mb-1">
                                                                <input
                                                                    value={locationList[0].amount}
                                                                    onChange={(e) => handleAmountInputChange(locationList[0], e.target.value)}
                                                                    className={`form-control form-control-solid h-auto py-2 px-6`}
                                                                    type="number"
                                                                    min="0"
                                                                />
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {/* <div className="text-center ">
                                                            <div className="mb-1">
                                                                
                                                                <button className="btn btn-xs btn-light-success btn-icon mr-2" 
                                                                    onClick={() => {
                                                                        handleAmountChange(locationList[0],locationList[0].amount + 1)
                                                                    }} >
                                                                    <i className="ki ki-plus icon-xs"></i>
                                                                </button>
                                                            </div>
                                                        </div> */}
                                                    </td>
                                                </tr>
                                            :
                                            null
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    
                        <div className="row col-12 justify-content-center">
                            <button type="button" className="btn btn-primary" onClick={() => addToCart()}>Add to Cart</button>
                        </div>
                    </Collapse>
                </CardBody>
            </Card>
        </div>
        
        <ProductModal
            modalStatus={showProductModal}
            setShowProductModal ={setShowProductModal}
            product={product}
            locations={locations}
            company={company}
            supplier={supplier}
            setShowSnackBar={setShowSnackBar}
        />

    <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">{"Alert"}</DialogTitle>
        <DialogContent>
        <DialogContentText id="alert-dialog-description">
            {dialogMessage}
        </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
            OK
        </Button>
        </DialogActions>
    </Dialog>
</>
        
    )
}