import {useHistory, useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {injectIntl} from "react-intl";
import {Button, Card, Table} from "react-bootstrap";
import makeStyles from "@material-ui/core/styles/makeStyles";
import OrderFavoritesInputField from "./OrderFavoritesInputField"
import {
    getProducts,
    fetchApprovedSuppliersCategories,
    getFavorite,
    updateFavorites
} from "./crud/ordersFavoritesCrud";
import {headerSortingClasses, sortCaret} from "../../../../_metronic/_helpers";
import * as columnFormatters from "./columnFormatters";
import {PaginationTable} from "../../../components/PaginationTable/PaginationTable";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import OrderFavoritesProductSequenceEdit from "./OrderFavoritesProductSequenceEdit";
import Link from "@material-ui/core/Link";

const useStyles2 = makeStyles(theme => ({
   root: {
       width: '100%',
       marginTop: theme.spacing(3)
   },
    table: {
       minWidth: 500,
    },
    tableWrapper: {
       overflowX: 'auto'
    }
}));

const initialData = {
    data: [],
    totalCount: 0,
    pageSize: 5
}

const sizePerPageList = [
    {text: "10", value: 10},
    {text: "20", value: 20},
    {text: "50", value: 50}
]

function OrderFavoritesEdit(props){

    const classes = useStyles2();

    const {userId, accessToken} = useSelector(
        ({auth}) => ({
            userId: auth.user.id,
            accessToken: auth.authToken
        })
    );

    const history = useHistory();
    const {id} = useParams();

    const [data, setData] = useState(initialData);

    const [products]  = useState([]);
    const [favoriteList, setFavoriteList] = useState({});
    const [favorites, setFavorites] = useState([]);
    const [flProducts, setFlProducts] = useState([]);
    const [favoritesMap, setFavoritesMap] = useState(new Map());
    const [name, setName] = useState("");
    const [suppliers, setSuppliers] = useState([]);
    const [selectedSupplier, setSelectedSupplier] = useState({});
    const [fetchingProducts, setFetchingProducts] = useState(false);
    const [isEditSequence, setIsEditSequence] = useState(false);

    const [alertTitle, setAlertTitle] = useState("");
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertText, setAlertText] = useState("");

    const columns = [
        {
            dataField: "name",
            text: "Name",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses: headerSortingClasses,
            formatter: columnFormatters.ProductNameFormatter
        },
        {
            dataField: "description",
            text: "Description",
            sort: true,
            sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.DescriptionFormatter
        },
        {
            dataField: "category",
            text: "Category",
            sort: true,
            sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.CategoryFormatter
        },
        // {
        //     dataField: "supplier.name",
        //     text: "Supplier",
        //     sort: true,
        //     sortCaret,
        //     headerSortingClasses,
        //     formatter: columnFormatters.SupplierFormatter
        // },
        {
            dataField: "favorites",
            text: "Favorite",
            sort: true,
            sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.FavoriteFormatter,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    addToFavorites(row);
                }
            }
        }
    ];

    const pagination = {custom: true, sizePerPageList: sizePerPageList};
    const sorting = [{dataField: "name", order: "asc"}];
    const tableClasses = "table table-head-custom table-head-bg table-borderless table-vertical-center";

    const fetchProducts = async (queryParams) => {

        if(selectedSupplier._id == null){
            return;
        }


        getProducts(queryParams, accessToken).then(response => {
            
            if(response.status === 200){
                const data = {
                    data: response.data.data,
                    totalCount: response.data.totalCount,
                    pageSize: 5
                };

                data.data.forEach(d => {
                    if(favoritesMap.get(d._id) != null){
                        d.favorite = true
                    } else{
                        d.favorite = false
                    }
                });


                setData(data);
                setFetchingProducts(false);
            }
        });
    }

    // const fetchSuppliers = () => {
    //     fetchApprovedSuppliersCategories(userId, accessToken).then(response =>{
    //         setSuppliers(response.data)
    //     })
    // }

    const prepareFilter = (queryParams, values) => {
        const {searchText} = values;
        const newQueryParams = {...queryParams};
        const filter = {};

        filter.name = searchText ?? "";


        newQueryParams.filter = filter;
        newQueryParams.supplierId = selectedSupplier._id ?? "";
        newQueryParams.pageSize = filter.pageSize ?? 10;

        return newQueryParams;
    }

    const initialFilter = {
        filter: {
            name: "",
            supplierId: ""
        },
        sortOrder: "asc",
        sortField: "name",
        pageNumber: 1,
        pageSize: 10
    }

    // 2. Add products to favorite list
    const addToFavorites = (product) => {
        const tempData = data;
        let favoriteFlag = null;
        tempData.data.forEach(p => {
            if(p.favorite == null){
                p.favorite = false
            }

            if(product._id === p._id){
                p.favorite = !p.favorite;
                favoriteFlag = p.favorite;
                return;
            }
        });
        setData({...data, data: [...tempData.data]})


        const tempFavorites = favorites;
        const tempFavoritesMap = favoritesMap;

        const index = tempFavorites.findIndex(p => p._id === product._id);
        if(favoriteFlag){
            tempFavorites.push(product);
            tempFavoritesMap.set(product._id, product);
        }else{
            const out = tempFavorites.splice(index, 1);
            tempFavoritesMap.delete(product._id);
        }

        console.log(tempFavorites);

        setFavorites([...tempFavorites]);
        setFavoritesMap(tempFavoritesMap);
    }
    // 3. Post List with all the products on the list
    const handleSaveList = async () => {
        const favorite = {
            name: name,
            products: favorites
        }

        if (favorite.name === null || favorite.name === ""){
            //TODO: This can be moved to a generic Context API Component
           fireAlert("Validation Error", "The name field is required");
           return;
        }else if(favorite.products.length <= 0){
            fireAlert("Validation Error", "You need to select at least one product");
            return;
        }else if(selectedSupplier._id == null){
            fireAlert("Validation Error", "The supplier field is required");
        }

        const response = await updateFavorites(id, favorite, accessToken)

        console.log("Updated", response.status);
        if(response.status !== 200){
            fireAlert("Server Error", response.data)
            return;
        }

        history.push('/orders-favorites');
    }

    const handleSaveListSequence = async () => {
        const favorite = {
            name: name,
            products: [...flProducts]
        }

        if (favorite.name === null || favorite.name === ""){
            //TODO: This can be moved to a generic Context API Component
           fireAlert("Validation Error", "The name field is required");
           return;
        }else if(favorite.products.length <= 0){
            fireAlert("Validation Error", "You need to select at least one product");
            return;
        }else if(selectedSupplier._id == null){
            fireAlert("Validation Error", "The supplier field is required");
        }

        const response = await updateFavorites(id, favorite, accessToken)

        if(response.status !== 200){
            fireAlert("Server Error", response.data)
            return;
        }

        setFlProducts([])


        history.push('/orders-favorites');
    }

    useEffect(() => {
        const queryParams = prepareFilter(initialFilter, {});
        // setFetchingProducts(true);
        fetchProducts(queryParams).then();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSupplier])

    useEffect(() => {

        getFavorite(id, accessToken).then(response => {
            console.log("Response: ", response)

            setFavoriteList({
                id: response.data._id,
                name: response.data.name,
                products: response.data.products
            });

            setFavorites(response.data.products);

            const favoritesTemp = new Map();

            response.data.products.forEach(p => {
                favoritesTemp.set(p._id, p)
            })

            setFavoritesMap(favoritesTemp);

            setSelectedSupplier(response.data.supplier[0]);
            setName(response.data.name);

            const queryParams = prepareFilter({}, {});
            setFetchingProducts(true);
            fetchProducts(queryParams).then();
        });

        fetchApprovedSuppliersCategories(userId, accessToken).then(response => {
            setSuppliers(response.data);
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const tempProducts = products;
       tempProducts.forEach((p, index) => {
          if(favoritesMap.get(p._id) !== null){
              tempProducts[index].favorite = true;
          }
       });

       // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [favoritesMap])

    const handleDialogClose = () => {
        setAlertOpen(false);
        setAlertTitle("");
        setAlertText("");
    }

    const fireAlert = (title, text) => {
       setAlertTitle(title);
       setAlertText(text);
       setAlertOpen(true)
    }

    return (
       <>
            <Link className="btn btn-primary mb-4" href="/orders-favorites">Back</Link>
           <Card>
              <Card.Header title="Add to Favorites List">
                  <h1>Favorites List</h1>
                  <OrderFavoritesInputField
                      data={name}
                      setData={setName}
                      placeholder={'Name'}
                      name={'name'}
                  />
                  <h5 className={'my-4'}>Products Selected: {favorites.length}</h5>
                  <h5 className={'my-4'}>Supplier: {selectedSupplier.entityName}</h5>
                  
                    <div className="row justify-content-between mb-3">
                        <div className="d-flex flex-wrap">
                        {
                            isEditSequence ?
                                <button className="btn btn-primary" onClick={() => setIsEditSequence(false)}>Add Products</button>
                            :
                                <button className="btn btn-primary" onClick={() => setIsEditSequence(true)}>Edit Sequence</button>
                        }
                        </div>
                        
                        <div className="d-flex">
                            {
                                isEditSequence ?
                                    <button className="btn btn-primary" onClick={() => handleSaveListSequence()}>Update List</button>
                                :
                                    <button className="btn btn-primary" onClick={() => handleSaveList()}>Update List</button>
                            }
                        </div>
                    </div>
              </Card.Header>
               <Card.Body>
                    {
                        isEditSequence ? 
                            <OrderFavoritesProductSequenceEdit 
                                accessToken={accessToken}
                                favoriteListId={favoriteList.id}
                                flProducts={flProducts}
                                setFlProducts={setFlProducts}
                            />
                        :
                            <PaginationTable
                                data={data}
                                columns={columns}
                                pagination={pagination}
                                isLoading={false}
                                sorting={sorting}
                                tableClasses={tableClasses}
                                fetcher={fetchProducts}
                                filterFunction={prepareFilter}
                                initialFilter={initialFilter}
                            />
                    }
                    

               </Card.Body>
           </Card>

           <div>
               <Dialog
                   open={alertOpen}
                   onClose={handleDialogClose}
                   aria-labelledby="alert-dialog-title"
                   aria-describedby="alert-dialog-description"
               >
                   <DialogTitle id="alert-dialog-title">{alertTitle}</DialogTitle>
                   <DialogContent>
                       <DialogContentText id='alert-dialog-description'>
                           {alertText}
                       </DialogContentText>
                   </DialogContent>
                   <DialogActions>
                      <Button onClick={handleDialogClose} color="primary" autoFocus>
                          OK
                      </Button>
                   </DialogActions>
               </Dialog>
           </div>
       </>
    )
}

export default injectIntl(OrderFavoritesEdit);