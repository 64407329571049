import React,  { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import { getCommonApplicationByCompanyId } from "../CommonApplication/CommonApplicationCrud"
import axios from "axios"

function Invoice({ invoice, user, accessToken, setIsInvoiceClicked }) {

    const [companyOwner, setCompanyOwner] = useState("")

    useEffect(() => {
        getCommonApplicationByCompanyId(invoice.companyId, accessToken).then((response) => {
            setCompanyOwner(response.data[0].personalInformation.personalFullLegalName)
        }).catch(error => console.log(error))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    let logoUrl = toAbsoluteUrl("/media/logos/allec-new-logo-m.png")
    if(user.tenant == process.env.REACT_APP_BIOWARE_TENANT || user.tenant == process.env.REACT_APP_BIOWARE_SUPPLIER_TENANT){
        logoUrl = toAbsoluteUrl("/media/logos/bioware-logo.png")
    }

    const handleBack = () => {
        setIsInvoiceClicked(false)
    }

    function toFixedCustom(num, fixed) {
        var re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
        return num.toString().match(re)[0];
    }

    const handlePrintInvoice = async () => {
        // console.log("Invoice: ", invoice)
        const salesOrderProductsData = invoice.products.map(currItem => {
            return {
                name: currItem.name,
                category: currItem.category,
                sku: currItem.sku,
                unitPrice: currItem.listPrice ? currItem.listPrice : currItem.price,
                units: currItem.amount,
                avgWeight: currItem.isCatchWeight ? currItem.avgWeight.toFixed(2) : "N/A",
                itemPrice: currItem.isCatchWeight ? currItem.estimatedPrice : currItem.listPrice ? currItem.listPrice : currItem.price,
                totalAmount: currItem.isCatchWeight ? (currItem.estimatedPrice * currItem.amount).toFixed(2) : currItem.listPrice ? (currItem.listPrice * currItem.amount).toFixed(2) : (currItem.price * currItem.amount).toFixed(2),
            }
        })
        const salesOrderData = {
            storeName: invoice.companyName,
            submissionDate: invoice.submissionDate,
            requestedDeliveryDate: invoice.shipDate,
            routeName: invoice.routes[0].name,
            orderNumber: invoice.orderNumber,
            salesRep: invoice.user[0].fullName,
            products: salesOrderProductsData,
            clientNumber: invoice.company[0].sageCustomerId,
            paymentTerm: invoice.company[0].dueDays,
            supplierName: invoice.supplier[0].entityName,
            supplierDescription: invoice.supplier[0].description,
            supplierPhone1: invoice.supplier[0].phoneNumber,
            supplierPhone2: invoice.supplier[0].phoneNumber2,
            supplierFax: invoice.supplier[0].fax,
            supplierAddress: `${invoice.supplier[0].physicalAddress}, ${invoice.supplier[0].physicalCity}, Puerto Rico, ${invoice.supplier[0].physicalZipcode}`,
            supplierDisclaimer: invoice.supplier[0].disclaimer,
            totalAmount: invoice.totalCost,
            sageInvoiceNumber: invoice.sageInvoiceNumber ? invoice.sageInvoiceNumber : false,
            salesTaxAmount: invoice.salesTaxAmount ? invoice.salesTaxAmount : false,
            totalCostTax: invoice.totalCostTax ? invoice.totalCostTax : false,
        }
        console.log("Sales Order Data: ", salesOrderData)

        const response = await axios.post("https://testwahmey.wms-test.allec.io/api/tenants/testwahmey/route_records/store_order_endpoint_pdf", 
        // const response = await axios.post("http://localhost:8003/api/tenants/wahmeybuyer/route_records/store_order_endpoint_pdf", 
        salesOrderData, {
            responseType: 'arraybuffer', // Ensure the response type is set to arraybuffer
        });

        console.log("Response: ", response)

        // Create a Blob from the PDF data
        const pdfBlob = new Blob([response.data], { type: 'application/pdf' });

        // Create a URL for the Blob
        const pdfUrl = URL.createObjectURL(pdfBlob);

        // Open a new tab with the PDF
        const newTab = window.open(pdfUrl, '_blank');
    }

    return (
        <div className="card card-custom overflow-hidden">
            <div className="card-body p-0">
                <div className="row justify-content-center bgi-size-cover bgi-no-repeat py-8 px-8 py-md-27 px-md-0" style={{backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-8.jpg")})`}} >
                    <div className="col-md-9">
                        <div className="d-flex justify-content-between pb-10 pb-md-20 flex-column flex-md-row">
                            <h1 className="display-4 text-white font-weight-boldest mb-10">INVOICE</h1>
                            <div className="d-flex flex-column align-items-md-end px-0">
                                {/* <a href="#" className="mb-5">
                                    <img src="assets/media/logos/logo-light.png" alt=""/>
                                </a> */}
                                <span id="invoiceDistribuyeLogo" className="text-white d-flex flex-column align-items-md-end">
                                    <img
                                        alt="Logo"
                                        className="max-h-80px"
                                        src={logoUrl}
                                    />
                                </span>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between text-white pt-6">
                            <div className="d-flex flex-column flex-root">
                                <span className="font-weight-bolder mb-2">Invoice Date</span>
                                <span className="">{invoice.invoiceDate}</span>
                            </div>
                            <div className="d-flex flex-column flex-root">
                                <span className="font-weight-bolder mb-2">Ship Date</span>
                                <span className="">{invoice.shipDate}</span>
                            </div>
                            { invoice.sageInvoiceNumber ? 
                                    <div className="d-flex flex-column flex-root">
                                        <span className="font-weight-bolder mb-2">Invoice NO.</span>
                                        <span className="">{invoice.sageInvoiceNumber}</span>
                                    </div>
                                :
                                    null
                            }
                            <div className="d-flex flex-column flex-root">
                                <span className="font-weight-bolder mb-2">Order NO.</span>
                                <span className="">{invoice.orderNumber}</span>
                            </div>
                            <div className="d-flex flex-column flex-root">
                                <span className="font-weight-bolder mb-2">Payment Term</span>
                                <span className="">{invoice.company[0].dueDays == 0 ? "COD" : `${invoice.company[0].dueDays} days`  }</span>
                            </div>
                            {/* <div className="d-flex flex-column flex-root">
                                <span className="font-weight-bolder mb-2">Order TO.</span>
                                <span className="">{invoice.supplierName}</span>
                            </div> */}
                        </div>
                        <div className="border-bottom w-100 opacity-20"></div>
                        <div className="d-flex justify-content-between text-white pt-6">
                            <div className="d-flex flex-column flex-root">
                                <span className="font-weight-bolder mb-2">Comments</span>
                                <span className="">{invoice.comment}</span>
                            </div>
                            <div className="d-flex flex-column flex-root">
                                <span className="font-weight-bolder mb-2">Cutomer PO</span>
                                <span className="">{invoice.customerPo}</span>
                            </div>
                            <div className="d-flex flex-column flex-root">
                                <span className="font-weight-bolder mb-2">Ship via</span>
                                <span className="">{invoice.shipVia}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center py-8 px-8 py-md-10 px-md-0">
                    <div className="col-md-9">
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th className="pl-0 font-weight-bold text-muted  text-uppercase">Product</th>
                                        <th className="text-right font-weight-bold text-muted text-uppercase">Sku</th>
                                        <th className="text-right font-weight-bold text-muted text-uppercase">Avg Weight</th>
                                        <th className="text-right font-weight-bold text-muted text-uppercase">Unit Price</th>
                                        <th className="text-right font-weight-bold text-muted text-uppercase">Item Price</th>
                                        <th className="text-right font-weight-bold text-muted text-uppercase">Product Qty.</th>
                                        <th className="text-right pr-0 font-weight-bold text-muted text-uppercase">Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {invoice.products.map(product => 
                                        <tr className="font-weight-boldest font-size-lg">
                                            <td className="border-top-0 pl-0 py-2 d-flex align-items-center">
                                                <div className="symbol symbol-40 flex-shrink-0 mr-4 bg-light">
                                                    <div className="symbol-label"
                                                        style={{backgroundImage: "url(" + product.imagePath + ")"}}></div>
                                                </div>
                                                {product.name}
                                            </td>
                                            <td className="border-top-0 text-right pt-7">{product.sku}</td>
                                            <td className="border-top-0 text-right pt-7">
                                                { 
                                                    product.isCatchWeight ?
                                                        toFixedCustom(product.avgWeight, 2)
                                                    :
                                                        "N/A"
                                                }
                                            </td>
                                            <td className="border-top-0 text-right pt-7">
                                                {product.listPrice?
                                                    <span className="label label-lg label-light-primary label-inline">${product.listPrice}</span>
                                                    :
                                                    <span>${product.price}</span>
                                                }
                                            </td>
                                            
                                            <td className="border-top-0 text-right pt-7">
                                                {
                                                    product.estimatedPrice ? 
                                                        <span className="label label-lg label-light-primary label-inline">${toFixedCustom((product.estimatedPrice), 2)}</span>
                                                    :
                                                        product.listPrice?
                                                            <span className="label label-lg label-light-primary label-inline">${toFixedCustom((product.listPrice), 2)}</span>
                                                        :
                                                            <span>${toFixedCustom((product.price), 2)}</span>
                                                }
                                            </td>
                                            <td className="border-top-0 text-right pt-7">{product.amount}</td>
                                            <td className="text-danger border-top-0 pr-0 pt-7 text-right">
                                                {
                                                    product.estimatedPrice ? 
                                                        <span className="label label-lg label-light-primary label-inline">${toFixedCustom((product.estimatedPrice * product.amount), 2)}</span>
                                                    :
                                                        product.listPrice?
                                                            <span className="label label-lg label-light-primary label-inline">${toFixedCustom((product.listPrice * product.amount), 2)}</span>
                                                        :
                                                            <span>${toFixedCustom((product.price * product.amount), 2)}</span>
                                                }
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center bg-gray-100 py-8 px-8 py-md-10 px-md-0">
                    <div className="col-md-9">
                        <div className="d-flex justify-content-between flex-column flex-md-row font-size-lg">
                            <div className="d-flex flex-column mb-10 mb-md-0">
                                {/* <div className="font-weight-bolder font-size-lg mb-3">BANK TRANSFER</div> */}

                                <div className="d-flex justify-content-between mb-1">
                                    <span className="mr-15 font-weight-bold">{companyOwner}</span>
                                </div>

                                <div className="d-flex justify-content-between mb-1">
                                    <span className="mr-15 font-weight-bold">{invoice.companyName}</span>
                                </div>

                                <div className="d-flex justify-content-between">
                                    <span className="mr-15 font-weight-bold">{invoice.locationAddressCity}</span>
                                </div>
                            </div>
                            { invoice.totalCostTax ? 
                                <div className="d-flex flex-column text-md-right">
                                    <span className="mb-1">Sub Total: {`$${invoice.totalCost}`}</span>
                                    <span className="mb-1">Sales Tax Amount: {`$${invoice.salesTaxAmount}`}</span>
                                    <span className="font-size-lg font-weight-bolder mb-1">TOTAL AMOUNT</span>
                                    <span className="font-size-h2 font-weight-boldest text-danger mb-1">
                                            {`$${invoice.totalCostTax}`}
                                    </span>
                                    <span>Taxes Included</span>
                                </div>
                            :
                                <div className="d-flex flex-column text-md-right">
                                    <span className="font-size-lg font-weight-bolder mb-1">TOTAL AMOUNT</span>
                                    <span className="font-size-h2 font-weight-boldest text-danger mb-1">
                                            {`$${toFixedCustom(invoice.totalCost, 2)}`}
                                    </span>
                                    <span>Taxes NOT Included</span>
                                </div>
                            }
                        </div>

                    </div>
                </div>
                <div className="row justify-content-center py-8 px-8 py-md-10 px-md-0">
                    <div className="col-md-9">
                        <div className="d-flex justify-content-between">
                            <button id="downloadInvoiceButton" type="button" className="btn btn-primary font-weight-bold" onClick={() => {
                                    // let printButton = document.getElementById("downloadInvoiceButton");
                                    // let downloadButton = document.getElementById("printInvoiceButton");
                                    // let logo = document.getElementById("invoiceDistribuyeLogo")

                                    // printButton.style.visibility = 'hidden'
                                    // downloadButton.style.visibility = 'hidden'
                                    // logo.style.visibility = 'hidden'

                                    // window.print()

                                    // printButton.style.visibility = 'visible'
                                    // downloadButton.style.visibility = 'visible'
                                    // logo.style.visibility = 'visible'
                                    handleBack()

                                }}>Back
                            </button>

                            <button id="printInvoiceButton" type="button" className="btn btn-light-primary font-weight-bold" onClick={() => {
                                    // let printButton = document.getElementById("downloadInvoiceButton");
                                    // let downloadButton = document.getElementById("printInvoiceButton");
                                    // let logo = document.getElementById("invoiceDistribuyeLogo")

                                    // printButton.style.visibility = 'hidden'
                                    // downloadButton.style.visibility = 'hidden'
                                    // logo.style.visibility = 'hidden'

                                    // window.print()

                                    // printButton.style.visibility = 'visible'
                                    // downloadButton.style.visibility = 'visible'
                                    // logo.style.visibility = 'visible'
                                    handlePrintInvoice()

                                }}>Print Invoice
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
    
export default injectIntl(Invoice);