import axios from "axios";

export const COMMON_APPLICATION_GET_URL = process.env.REACT_APP_API_URL + "/commonApplication/";
export const COMMON_APPLICATION_POST_URL = process.env.REACT_APP_API_URL + "/commonApplication";
export const COMPANY_URL = process.env.REACT_APP_API_URL + "/company";
export const COMPANY_PUT_URL = process.env.REACT_APP_API_URL + "/company/";
export const USER_COMPANY_GET_URL = process.env.REACT_APP_API_URL + "/company/user/";
export const APPLICATIONS_URL = process.env.REACT_APP_API_URL + "/applications";
export const APPLICATIONS_GET_BY_USER_COMPANIES_URL = process.env.REACT_APP_API_URL + "/applications/company";
export const LOCATIONS_URL = process.env.REACT_APP_API_URL + "/locations"

export function getCompaniesLocations(id, queryParams, accessToken) {
  const options = {
    headers: { 'x-auth-token': accessToken },
    validateStatus: status => status < 400,
    params: queryParams
  }
  // console.log(companyIdList)
  return axios.get(`${COMPANY_URL}/${id}/locations`, options);
}

export function getCommonApplicationByCompanyId(companyId, accessToken) {
  const config = {
    headers: { 'x-auth-token': accessToken }
  }
  return axios.get(COMMON_APPLICATION_GET_URL + companyId, config)
}

export function postCommonApplication(commonApplication, accessToken) {
  // Authorization head should be fulfilled in interceptor.
  const config = {
    headers: { 'x-auth-token': accessToken }
  }
  return axios.post(COMMON_APPLICATION_POST_URL, commonApplication, config);
}

export function postCommonApplicationDocuments(files, companyId, accessToken) {
  // Authorization head should be fulfilled in interceptor.
  const config = {
    headers: { 'x-auth-token': accessToken }
  }
  return axios.post(`${COMMON_APPLICATION_POST_URL}/documents/${companyId}`, files, config);
}

export function putCommonApplication(commonApplication, id, accessToken) {
  const config = {
    headers: { 'x-auth-token': accessToken }
  }
  // Authorization head should be fulfilled in interceptor.
  return axios.put(COMMON_APPLICATION_GET_URL + id, commonApplication, config);
}

export function postCompany(company, accessToken) {
  const config = {
    headers: { 'x-auth-token': accessToken }
  }
  return axios.post(COMPANY_URL, company, config);
}

export function putCompanyLocations(locations, id, accessToken) {
  const config = {
    headers: { 'x-auth-token': accessToken }
  }
  return axios.put(COMPANY_PUT_URL + id + "/locations", locations, config);
}

export function putCompanyApplications(applications, id, accessToken) {
  const config = {
    headers: { 'x-auth-token': accessToken }
  }
  return axios.put(COMPANY_PUT_URL + id, applications, config);
}

export function getCompanies(userId, accessToken) {
  const config = {
    headers: { 'x-auth-token': accessToken }
  }
  return axios.get(USER_COMPANY_GET_URL + userId, config)
}

export function postApplications(applications, accessToken, isSendSupplierEmails, isSendBuyerEmail) {
  const config = {
    headers: { 'x-auth-token': accessToken }
  }
  return axios.post(APPLICATIONS_URL, applications, config);
}

export function putApplication(application, id, accessToken, supplierEmail, companyEmail, isSendEmail, statusChanged, draftApplicationId) {
  const config = {
    headers: { 'x-auth-token': accessToken },
    params: {
      supplierEmail,
      companyEmail,
      isSendEmail,
      statusChanged,
      draftApplicationId
    }
  }
  // Authorization head should be fulfilled in interceptor.
  return axios.put(APPLICATIONS_URL + `/${id}`, application, config);
}

export function patchApplicationsLocations(data, accessToken) {
  const config = {
      headers: { 'x-auth-token': accessToken}
  }
  return axios.patch(`${APPLICATIONS_URL}/newLocations`, data, config);
}

export function getApplicationsByCompanyIdList(accessToken) {
  const config = {
    headers: { 'x-auth-token': accessToken }
  }
  // console.log(companyIdList)
  return axios.get(APPLICATIONS_GET_BY_USER_COMPANIES_URL, config);
}

export function getApplicationsByCompanyIdListPagination(companyIdList, queryParams, accessToken) {
  const options = {
    headers: { 'x-auth-token': accessToken },
    validateStatus: status => status < 400,
    params: { companyIdList, queryParams }
  }
  // console.log(companyIdList)
  return axios.get(`${APPLICATIONS_GET_BY_USER_COMPANIES_URL}/pagination`, options);
}

export function postLocations(locationsList, accessToken) {
  const config = {
    headers: { 'x-auth-token': accessToken }
  }
  return axios.post(LOCATIONS_URL, locationsList, config);
}

export function getLocationsByCompanyIdList(companyIdList, accessToken) {
  const config = {
    headers: { 'x-auth-token': accessToken }
  }
  // console.log(companyIdList)
  return axios.get(LOCATIONS_URL + '/company', { params: companyIdList }, config);
}

export function getLocationsByCompanyIdListPagination(queryParams, accessToken) {
  const options = {
    headers: { 'x-auth-token': accessToken },
    validateStatus: status => status < 400,
    params: { queryParams }
  }
  // console.log(companyIdList)
  return axios.get(LOCATIONS_URL + '/companies/pagination', options);
}

export function getApplicationsByLocation(locationId, accessToken) {
  const config = {
    headers: { 'x-auth-token': accessToken }
  }
  return axios.get(`${APPLICATIONS_URL}/location/${locationId}`, config)
}