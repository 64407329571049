import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {injectIntl} from "react-intl";
import {Button, Card} from "react-bootstrap";
import {
    FilledInput,
    FormControl,
    InputLabel, MenuItem,
    Select,
} from "@material-ui/core";
import OrderFavoritesInputField from "./OrderFavoritesInputField"
import  {getProducts, postFavorites, fetchApprovedSuppliersCategories} from "./crud/ordersFavoritesCrud";
import {headerSortingClasses, sortCaret} from "../../../../_metronic/_helpers";
import * as columnFormatters from "./columnFormatters";
import {PaginationTable} from "../../../components/PaginationTable/PaginationTable";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Link from "@material-ui/core/Link";

// const useStyles2 = makeStyles(theme => ({
//    root: {
//        width: '100%',
//        marginTop: theme.spacing(3)
//    },
//     table: {
//        minWidth: 500,
//     },
//     tableWrapper: {
//        overflowX: 'auto'
//     }
// }));

const initialData = {
    data: [],
    totalCount: 0,
    pageSize: 5
}

const sizePerPageList = [
    {text: "10", value: 10},
    {text: "20", value: 20},
    {text: "50", value: 50}
]

function OrderFavoritesNew(props){

    // const classes = useStyles2();

    const {userId, accessToken} = useSelector(
        ({auth}) => ({
            userId: auth.user.id,
            accessToken: auth.authToken
        })
    );

    const history = useHistory();

    const [data, setData] = useState(initialData);

    const [favorites, setFavorites] = useState([]);
    const [favoritesMap, setFavoritesMap] = useState(new Map());
    const [name, setName] = useState("");
    const [suppliers, setSuppliers] = useState([]);
    const [selectedSupplier, setSelectedSupplier] = useState({_id: ""});
    const [fetchingProducts, setFetchingProducts] = useState(false);
    const [isSupplierSelected, setIsSupplierSelected] = useState(false);

    const [alertTitle, setAlertTitle] = useState("");
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertText, setAlertText] = useState("");

    const columns = [
        {
            dataField: "name",
            text: "Name",
            sort: true,
            sortCaret: sortCaret,
            headerSortingClasses: headerSortingClasses,
            formatter: columnFormatters.ProductNameFormatter
        },
        {
            dataField: "description",
            text: "Description",
            sort: true,
            sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.DescriptionFormatter
        },
        {
            dataField: "category",
            text: "Category",
            sort: true,
            sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.CategoryFormatter
        },
        // {
        //     dataField: "supplier.name",
        //     text: "Supplier",
        //     sort: true,
        //     sortCaret,
        //     headerSortingClasses,
        //     formatter: columnFormatters.SupplierFormatter
        // },
        {
            dataField: "favorites",
            text: "Favorite",
            sort: true,
            sortCaret,
            headerSortingClasses,
            formatter: columnFormatters.FavoriteFormatter,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {
                    console.log("This is the clicked product: ", row);
                    addToFavorites(row)
                }
            }
        }
    ];

    const pagination = {custom: true, sizePerPageList: sizePerPageList};
    const sorting = [{dataField: "name", order: "asc"}];
    const tableClasses = "table table-head-custom table-head-bg table-borderless table-vertical-center";

    const fetchProducts = async (queryParams) => {
        
        if(fetchingProducts){
            return;
        }

        getProducts(queryParams, accessToken, selectedSupplier._id).then(response => {
            console.log("Items: ", response.data.data)
            if(response.status === 200){
                const data = {
                    data: response.data.data,
                    totalCount: response.data.totalCount,
                    pageSize: 5
                };

                data.data.forEach(d => {
                    if(favoritesMap.get(d._id) != null){
                        d.favorite = true
                    } else{
                        d.favorite = false
                    }
                });

                setData(data);
                setFetchingProducts(false);
            }
        })
    }

    // const fetchSuppliers = () => {
    //     fetchApprovedSuppliersCategories(userId, accessToken).then(response =>{
    //         setSuppliers(response.data)
    //     })
    // }

    const prepareFilter = (queryParams, values) => {
        const {searchText} = values;
        const newQueryParams = {...queryParams};
        const filter = {};

        filter.name = searchText ?? "";


        newQueryParams.filter = filter;
        newQueryParams.supplierId = selectedSupplier._id ?? "";
        newQueryParams.pageNumber = filter.pageNumber ?? 1;
        newQueryParams.pageSize = filter.pageSize ?? 10;

        return newQueryParams;
    }

    const initialFilter = {
        filter: {
            name: "",
            supplierId: ""
        },
        sortOrder: "asc",
        sortField: "name",
        pageNumber: 1,
        pageSize: 10
    }

    // 2. Add products to favorite list
    const addToFavorites = (product) => {
        const tempFavorites = favorites;
        tempFavorites.push(product);
        setFavorites([...tempFavorites]);

        const tempFavoritesMap = favoritesMap;
        tempFavoritesMap.set(product._id, product);
        setFavoritesMap(tempFavoritesMap);

        const tempData = data;
        tempData.data.forEach(p => {
            if(product._id === p._id){
               p.favorite = true
            }
        });
        setData({...data, data: [...tempData.data]})
        console.log(data);
    }
    // 3. Post List with all the products on the list
    const handleSaveList = async () => {
        const favorite = {
            name: name,
            products: favorites
        }

        if (favorite.name === null || favorite.name === ""){
            //TODO: This can be moved to a generic Context API Component
           fireAlert("Validation Error", "The name field is required");
           return;
        }else if(favorite.products.length <= 0){
            fireAlert("Validation Error", "You need to select at least one product");
            return;
        }else if(selectedSupplier._id == null){
            fireAlert("Validation Error", "The supplier field is required");
        }

        const response = await postFavorites(favorite, selectedSupplier._id, accessToken)
        console.log(response.status, response.data);
        if(response.status !== 200){
            fireAlert("Server Error", response.data)
            return;
        }

        history.push('/orders-favorites');
    }

    const handleSupplierChange = (element) => {
        const clickedSupplier = suppliers.find(s => s._id === element.target.value);

        setFavorites([]);
        setFavoritesMap(new Map());
        setSelectedSupplier(clickedSupplier);
        setIsSupplierSelected(true)
    }

    useEffect(() => {
        const queryParams = prepareFilter({}, {});
        
        if(isSupplierSelected){
            setFetchingProducts(true);
            fetchProducts(queryParams).then();
        }
        

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSupplier])

    useEffect(() => {
        fetchApprovedSuppliersCategories(userId, accessToken).then(response => {
            if(response.data.length == 1){
                setSelectedSupplier(response.data[0])
                setIsSupplierSelected(true)
            }
            setSuppliers(response.data);
            
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleDialogClose = () => {
        setAlertOpen(false);
        setAlertTitle("");
        setAlertText("");
    }

    const fireAlert = (title, text) => {
       setAlertTitle(title);
       setAlertText(text);
       setAlertOpen(true)
    }

    return (
       <>
        <Link className="btn btn-primary mb-4" href="/orders-favorites">Back</Link>
           <Card>
              <Card.Header title="Add to Favorites List">
                  <h1>Favorites List</h1>
                  <OrderFavoritesInputField
                      data={name}
                      setData={setName}
                      placeholder={'Name'}
                      name={'name'}
                  />
                  <h5 className={'my-4'}>Products Selected: {favorites.length}</h5>
                  <FormControl variant="filled" style={{width: "100%"}}>
                     <InputLabel>Supplier</InputLabel>
                     <Select
                         value={selectedSupplier._id}
                         style={{minWidth: "200px"}}
                         input={<FilledInput name="supplier" id="supplier"
                                             onChange={(element) =>{
                                                 handleSupplierChange(element)
                                             }}
                                             type="text"
                                             className="form-control form-control-solid h-auto"
                                             />}>
                      {suppliers.map((supplierItem, index) => (
                          <MenuItem selected={index === 0} value={supplierItem._id}>{supplierItem.entityName}</MenuItem>
                      ))
                      }
                     </Select>

                  </FormControl>
                  <button className={'btn btn-primary my-4'} onClick={handleSaveList}>Save List</button>
              </Card.Header>
               <Card.Body>
                    { isSupplierSelected ?
                            <PaginationTable
                                data={data}
                                columns={columns}
                                pagination={pagination}
                                isLoading={false}
                                sorting={sorting}
                                tableClasses={tableClasses}
                                fetcher={fetchProducts}
                                filterFunction={prepareFilter}
                                initialFilter={initialFilter}
                            />
                        :
                            null
                    }
               </Card.Body>
           </Card>

           <div>
               <Dialog
                   open={alertOpen}
                   onClose={handleDialogClose}
                   aria-labelledby="alert-dialog-title"
                   aria-describedby="alert-dialog-description"
               >
                   <DialogTitle id="alert-dialog-title">{alertTitle}</DialogTitle>
                   <DialogContent>
                       <DialogContentText id='alert-dialog-description'>
                           {alertText}
                       </DialogContentText>
                   </DialogContent>
                   <DialogActions>
                      <Button onClick={handleDialogClose} color="primary" autoFocus>
                          OK
                      </Button>
                   </DialogActions>
               </Dialog>
           </div>
       </>
    )
}

export default injectIntl(OrderFavoritesNew);